import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { OrgswitchComponent } from './auth/orgswitch/orgswitch.component';
import { PendingChangesGuard } from './components/guards/pendingchangesguard';
import { HomeComponent } from './home/home.component';
import { FrogRoutes } from './components/route';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationComponent } from './organizations/organization/organization.component';
import { EditorganizationComponent } from './organizations/editorganization/editorganization.component';
import { EdituserComponent } from './users/edituser/edituser.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/user/user.component';
import { EditorganizationuserComponent } from './users/editorganizationuser/editorganizationuser.component';
import { UsermagicmoneyComponent } from './users/edituser/usermagicmoney/usermagicmoney.component';
import { RolesComponent } from './roles/roles.component';
import { RoleComponent } from './roles/role/role.component';
import { EditRoleComponent } from './roles/editrole/editrole.component';
import { EmailsComponent } from './emailmanagement/emails.component';
import { EditEmailComponent } from './emailmanagement/editemail/editemail.component';

const routes: FrogRoutes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'switchorganizations/:id', component: OrgswitchComponent },
  { path: 'organizations', component: OrganizationsComponent },
  { path: 'organizations/:id', component: OrganizationComponent },
  { path: 'organizations/edit/:id', component: EditorganizationComponent, canDeactivate: [PendingChangesGuard]},
  { path: 'roles', component : RolesComponent},
  { path: 'roles/:id', component: RoleComponent },
  { path: 'roles/edit/:id', component: EditRoleComponent, canDeactivate: [PendingChangesGuard] },
  { path: 'users', component: UsersComponent },
  { path: 'users/:id', component: UserComponent },
  { path: 'users/edit/:id', component: EdituserComponent, canDeactivate: [PendingChangesGuard]},
  { path: 'users/:id/organizationuser/edit/:id', component: EditorganizationuserComponent, canDeactivate: [PendingChangesGuard]},
  { path: 'emailtemplates', component: EmailsComponent },
  { path: 'emailtemplates/:id', component: EditEmailComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
