<div *ngIf="UserId != null" class="field has-addons">
  <div class="control is-expanded">
    <input class="input" [value]="User.LastName + ', ' + User.FirstName" disabled>
    
  </div>
  <div class="control" *ngIf="CanClear">
    <button type="button" class="button is-light" (click)="ClearUser()">
      <i class="fa fa-trash"></i>
    </button>
  </div>
  
</div>
<div *ngIf="UserId == null" class="ng-autocomplete position" style="width:100%;" (keypress)="KeyPress($event)">
  <ng-autocomplete 
    [data]="SearchResults"
    [searchKeyword]="SearchTerm"
    placeholder="Select user"
    (selected)='ItemSelected($event)'
    (inputChanged)='SearchChanged($event)'
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [debounceTime]="300"
    [isLoading]="IsLoading"
    (inputCleared)="Cleared()"
    >
  </ng-autocomplete>
  <ng-template #itemTemplate let-item>
      <a [innerHTML]="item.FirstName + ' ' + item.LastName + ' (' + item.Email + ')'"></a>
  </ng-template>
  
  <ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
  </ng-template>
</div>