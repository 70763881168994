import { Datalistrequestfilter } from "../datalistrequestfilter";

export interface IFilter {
}

export interface ISearchTermFilter extends IFilter {
    SearchTerm: string
}

export interface ISubsetsFilter extends IFilter {
    Subset: string
}

export interface IFilterFilters extends IFilter {
    Filters: Array<Datalistrequestfilter>;
}

export function isSearchTermFilter(obj: any): obj is ISearchTermFilter {
    return 'SearchTerm' in obj;
}

export function isSubsetFilter(obj: any): obj is ISubsetsFilter {
    return 'Subset' in obj;
}

export function isFilterFilters(obj: any): obj is IFilterFilters {
    return 'Filters' in obj;
}