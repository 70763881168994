import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validationsummary',
  templateUrl: './validationsummary.component.html',
  styleUrls: ['./validationsummary.component.scss']
})
export class ValidationsummaryComponent implements OnInit {

  @Input()
  public Form !: FormGroup;

  constructor() { }

  ngOnInit(): void {
    if(this.Form == null){
      
    }
  }

}
