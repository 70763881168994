import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as ace from 'ace-builds';
ace.config.set("basePath", "/scripts/ace");

@Component({
  selector: 'app-codeeditor',
  templateUrl: './codeeditor.component.html',
  styleUrls: ['./codeeditor.component.scss']
})
export class CodeeditorComponent implements OnInit {

  @Input()
  public Mode : any = 'html';

  @Input()
  public Model : any = '';
  @Output()
  public ModelChange = new EventEmitter<string>();

  public StyleEditorOptions : any = {maxLines: 8000, printMargin: false};

  constructor() { }

  ngOnInit(): void {
  }

  public OnChange(text : any){
    this.ModelChange.emit(text);
  }

}
