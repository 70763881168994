import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ContentChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { ColorDisplaycolumn, Datalistviewcolumn } from '../datalistviewcolumn';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { Busyable } from '../busyable';
import { catchError, empty, ObservableInput, throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Deviceicons } from 'src/app/util/deviceicons';
import { Datalistrequestfilter } from '../datalistrequestfilter';
import { SimpleModalService } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { DateRangeComponent, DisplayDate } from '../inputs/daterange/daterange.component';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { DateFilterComponent } from '../filters/date-filter/date-filter.component';
import { FilterSetComponent } from '../filters/filter-set/filter-set.component';
import { faShekel } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-datalistview',
  templateUrl: './datalistview.component.html',
  styleUrls: ['./datalistview.component.scss']
})
export class DatalistviewComponent extends Busyable implements OnInit, AfterContentInit {
  @Input()
  public Columns: Array<Datalistviewcolumn> = [];

  @Input()
  public AllowEdit: boolean = false;
  @Input()
  public EditUrl: string = "";
  @Input()
  public EditUrlPerItem: boolean = false;
  @Input()
  public AllowCustomAction: boolean = false;
  @Input()
  public CustomAction: Function | undefined;
  @Input()
  public ApiUrl: string = "";
  @Input()
  public ShowMap: boolean = false;
  @Input()
  public AllowSelect: boolean = false;
  @Input()
  public PageSize: number | null = null;
  @Input()
  public ShowPaging: boolean = true;
  @Input()
  public AllowSelectBoxes : boolean = false;
  @Output()
  public SelectBoxChanged = new EventEmitter<any>();
  @Input()
  public SelectedItems: any[] = [];
  @Input()
  public DisableIfSelected: boolean = false;
  @Input()
  public set Filters(val: Array<Datalistrequestfilter>) {

    this.Filter.Filters = val;
  }
  @Input()
  public PreLoad: boolean = true;
  @Input()
  public HighLightRowColumn: string = "";
  @Input()
  public HighLightCondition: string[] = [];
  @Output()
  public AddingNew = new EventEmitter<any>();
  @Output()
  public Editing = new EventEmitter<any>();

  public MapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  @ViewChild(GoogleMap)
  public Map!: GoogleMap;

  @ContentChild(FilterSetComponent)
  public FilterSet?: FilterSetComponent;

  public Filter = {
    PageNumber: 1,
    PageSize: 15,
    SearchTerm: "",
    Subset: "",
    Filters: [] as Array<Datalistrequestfilter>
  };
  public Data: any = { PageNumber: 1, PageSize: 0, Results: null };
  public MapPolygons: any = [];
  public MapPoints: any[] = [];

  public Pages: Array<Array<number>> = [];
  public PageCount: number = 0;
  public MapOptions: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4,
  };
  public SelectedRow: any = null;
  public AddHasOptions: boolean = false;
  public showAddDropdown: boolean = false;
  @Output()
  public ItemSelected = new EventEmitter<any>();
  private localStorageKey = "Data_View_Filter";

  constructor(private apiService: ApiServiceBase, private router: Router, public sanitizer: DomSanitizer, private modalService: SimpleModalService, private localStorageService: LocalStorageService) {
    super();
    this.localStorageKey = this.router.url + '-' + this.localStorageKey;
  }

  ngOnInit(): void {
    if (this.PageSize != null) {
      this.Filter.PageSize = this.PageSize;
    }
  }


  public formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  public padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }


  ngAfterContentInit(): void {

    if (this.FilterSet != undefined) {
      this.FilterSet.applySearch.subscribe((filterValues) => {
        this.Filter = filterValues;
        this.Refresh();
      });
    }


    if (this.PreLoad) {
      this.Loading();
      if (this.FilterSet != undefined) {
        this.Filter = this.FilterSet.getFilterValues();
      }
      this.Refresh();
    }
  }


  public async Search() {
    this.Filter.PageNumber = 1;
    this.localStorageService.Save(this.localStorageKey, this.Filter);
    this.Refresh();
  }

  public async ClearSearch() {
    this.Filter.SearchTerm = '';
    this.Filter.PageNumber = 1;
    this.Filter.Subset = '';
    this.localStorageService.Clear(this.localStorageKey);
    this.Refresh();
  }

  public async Refresh() {

    this.localStorageService.Save(this.localStorageKey, this.Filter);
    this.Loading();
    this.apiService.Post(this.ApiUrl, this.Filter).then(result => {
      if (result != null) {
        this.Data = result;

        // if (this.EditUrlPerItem) {
        //   this.Data.Results.forEach((element: any) => {
        //     element.EditUrl = this.alertsService.MapEntityRoute(element.ClassName);
        //   });
        // }

        this.RecountPages();
        if (this.ShowMap) {
          this.PopulateMapData();
        }
      }
      this.StopLoading();
    });
  }


  private RecountPages() {
    if (this.ShowPaging == false) {
      return; //not showing paging so don't calculate pages
    }
    //if Math.Floor(150/50) == 150/50, which is true because 3 == 3, we dont need to add another page (3 Pages of 50 results)
    //otherwise we add another page - e.g Floor(152/50) != (152/30) because 3 != 3.04, 
    //so we add another page to account for the remaining results (2) 
    //(3 Pages of 50, and 1 page of 2)
    let flooredPages = Math.floor(this.Data.TotalRecords / this.Data.PageSize);
    let totalPages = flooredPages === (this.Data.TotalRecords / this.Data.PageSize) ? flooredPages : flooredPages + 1;

    this.PageCount = totalPages;
    this.Pages = [];

    let maxDisplay = 10;
    if (totalPages <= maxDisplay) { //less than max so show all
      let p1: Array<number> = [];
      for (let i = 1; i <= totalPages; i++) {
        p1.push(i);
      }
      this.Pages.push(p1);
    }
    else {

      let midpoint = this.Data.PageNumber;
      let count = Math.floor(maxDisplay / 2) - 1;
      if (midpoint <= count) midpoint = count + 1;
      if (midpoint - count > 2) this.Pages.push([1]);
      if (midpoint + count > totalPages) midpoint = totalPages - (count - 1);
      let midArray = [];
      if (midpoint - count == 2) midArray.push(1);
      for (let i = midpoint - count; i < midpoint + count; i++) {
        midArray.push(i);
      }
      this.Pages.push(midArray);
      if (midArray[midArray.length - 1] < totalPages)
        this.Pages.push([totalPages]);
    }
    if (this.Pages.length == 0) {
      this.Pages.push([1]);
    }
  }
  public PageSizeChanged() {
    this.Refresh();
  }

  public NextPage() {
    if (this.Filter.PageNumber < this.PageCount) {
      this.Filter.PageNumber += 1;
      this.Refresh();
    }
  }
  public PrevPage() {
    if (this.Filter.PageNumber > 1) {
      this.Filter.PageNumber -= 1;
      this.Refresh();
    }
  }
  public GotoPage(page: number) {
    if (page != this.Filter.PageNumber) {
      this.Filter.PageNumber = page;
      this.Refresh();
    }
  }

  private PopulateMapData() {
    this.MapPolygons = [];
    this.MapPoints = [];
    for (let i = 0; i < this.Data.Results.length; i++) {
      if (this.Data.Results[i]["GeoLocationPoints"] != null) {
        let loc = this.Data.Results[i]["GeoLocationPoints"];
        if (loc.length == 2) {
          let marker: google.maps.LatLngLiteral = { lat: loc[1], lng: loc[0] };
          let icon = { url: Deviceicons.GenerateIconName(this.Data.Results[i], true, true), scaledSize: new google.maps.Size(40, 40) }
          this.MapPoints.push({ marker: marker, label: this.Data.Results[i].Name, icon: icon });
        }
      }
      if (this.Data.Results[i]["LocationPoints"] != null) {
        let loc = this.Data.Results[i]["LocationPoints"];
        if (loc.length == 2) {
          let marker: google.maps.LatLngLiteral = { lat: loc[1], lng: loc[0] };
          let icon = { url: Deviceicons.GenerateIconName(this.Data.Results[i], true, true), scaledSize: new google.maps.Size(40, 40) }
          this.MapPoints.push({ marker: marker, label: this.Data.Results[i].Name });
        }
      }
      if (this.Data.Results[i]["GeoPolygonPoints"] != null) {
        let loc = this.Data.Results[i]["GeoPolygonPoints"];
        if (loc.length > 0) {
          let poly: google.maps.LatLngLiteral[] = [];
          for (let j = 0; j < loc.length; j++) {
            poly.push({ lat: loc[j][1], lng: loc[j][0] });
          }

          this.MapPolygons.push(poly);
          this.Data.Results[i].Polygon = poly;
        }
      }
    }
    if (this.MapPoints.length > 0) {
      this.Map.panTo({ lat: this.MapPoints[0].marker.lat, lng: this.MapPoints[0].marker.lng });
    } else if (this.MapPolygons.length > 0) {
      this.Map.panTo({ lat: this.MapPolygons[0][0].lat, lng: this.MapPolygons[0][0].lng });
    }
  }

  public RowClicked(row: any) {
    this.SelectedRow = row;
    if (this.ShowMap) {
      if (row.GeoLocationPoints != null) {
        this.Map.panTo({ lat: row.GeoLocationPoints[1], lng: row.GeoLocationPoints[0] });
      }
      else if (row.GeoPolygonPoints != null) {
        this.Map.panTo({ lat: row.GeoPolygonPoints[0][1], lng: row.GeoPolygonPoints[0][0] });
      }
    }
  }

  public EditItem(item: any) {
    if (this.AllowEdit == false) {
      return;
    }
    if (this.EditUrlPerItem && item.EditUrl != null) {
      this.router.navigate([item.EditUrl, item.Id]);
    }
    if (this.EditUrl != null && this.EditUrl != '') {

      let count = this.EditUrl.split(':').length-1;
      if(count > 1){
        //always leave the last item for the router navigate
        while(count > 1){
            let pFrom = this.EditUrl.indexOf(":");
            let pTo = this.EditUrl.indexOf("/", pFrom);
            if(pTo < 0){
              pTo = this.EditUrl.length;
            }
            let result = this.EditUrl.substring(pFrom, pTo);
            console.log(result);
            count = count -1;

            let value = result.replace(":", "");
            this.EditUrl = this.EditUrl.replace(result, item[value]);
            console.log(this.EditUrl);
        }
      }
      if (this.EditUrl.indexOf(":")) { //clean up the edit url if it has the :id at the end
        this.EditUrl = this.EditUrl.substring(0, this.EditUrl.indexOf(":"));
      }
      this.router.navigate([this.EditUrl, item.Id]);
    }
    this.Editing.emit(item.Id);
  }

  public SelectItem(item: any) {
    this.ItemSelected.emit(item);
  }

  public CustomActionOnAction(item: any) {
    if (this.CustomAction == null) {
      return;
    }
    this.CustomAction(item);
  }

  private HandleApiError(err: any): ObservableInput<any> {

    return throwError(() => err);
  }

  public RowSelectChanged(evt: any, row : any){
    this.SelectBoxChanged.emit({'Selected': evt.target.checked, 'Id': row["Id"], 'LocationString': row['LocationString'], 'Name': row['Name']});
  }

  public IsInSelectedItems(row : any){
    var item = this.SelectedItems.filter((x: any) => x == row["Id"]);
    if(item.length > 0){
      return true;
    }
    return false;
  }

  public getClass(row: any): string {

    if (this.SelectedRow == row) {
      return '';
    }
    if (this.HighLightRowColumn == '' || this.HighLightCondition.length == 0) {
      return ''
    }

    for (let i = 0; i <= this.HighLightCondition.length; i++) {
      if (row[this.HighLightRowColumn] == this.HighLightCondition[i]) {
        return 'row-highlight';
      }
    }

    return '';
  }
}
