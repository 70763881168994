<div class="columns p-2 m-0 mb-5" style="">
  <div class="column is-7">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li *ngFor="let b of Breadcrumbs"><a [routerLink]="b[1]">{{b[0]}}</a></li>
        <li class="is-active has-text-weight-bold"><a href="#" aria-current="page">{{displayTitle}}</a></li>
      </ul>
    </nav>
  </div>
  <div class="column is-5 has-text-right">
    <button *ngIf="EnableEdit" class="button is-link" [routerLink]="editRouterLink" routerLinkActive="active"
      [fragment]="Title">
      <span class="icon-text">
        <span> Edit </span>
        <span class="icon">
          <i class="fa fa-chevron-right"></i>
        </span>
      </span>
    </button>

    <button *ngIf="EnableDelete" type="button" class="button is-danger ml-3 mr-3" (click)="Delete()">
      <span class="icon-text">
        <span>Delete</span>
        <span class="icon">
          <i class="fa fa-trash"></i>
        </span>
      </span>
    </button>

    <ng-content></ng-content>

  </div>
</div>
<div *ngIf="Form">
  <app-validationsummary [Form]="Form"></app-validationsummary>
</div>