import { LoginServiceBase } from "src/app/auth/login.service.base";
import { LoginService } from "src/app/auth/login.service";
import { Mockloginservice } from "src/app/auth/mock.login.service.service";
import { ApiServiceBase } from "src/app/Services/api.service.base";
import { ApiService } from "src/app/Services/api.service";
import { Mockapiservice } from "src/app/Services/mock.api.service";

const ProductionApi = [
  { provide: ApiServiceBase, useClass: ApiService },
  { provide: LoginServiceBase, useClass: LoginService }
]
const MockApi = [
  { provide: ApiServiceBase, useClass: Mockapiservice },
  { provide: LoginServiceBase, useClass: Mockloginservice }
]

export const environment = {
  production: false,
  bannerText: "DEV",
  serverBaseUrl: "https://node1-dev.frogparking.com",
  apiTarget: ProductionApi
};
