import { JsonpClientBackend } from "@angular/common/http";
import { AfterContentInit, AfterViewInit, Component, Inject, inject, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, CanDeactivate, Router } from "@angular/router";
import { catchError, Observable, of, Subject } from "rxjs";
import { ApiService } from "../Services/api.service";
import { Busyable } from "./busyable";
import { ComponentCanDeactivate } from "./guards/pendingchangesguard";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { faV } from "@fortawesome/free-solid-svg-icons";
import { SimpleModalService } from "ngx-simple-modal";
import { HardwaremessagesviewerComponent } from "./hardwaremessagesviewer/hardwaremessagesviewer.component";
import { Deviceicons } from "../util/deviceicons";
import { ApiServiceBase } from "../Services/api.service.base";

@Component({
    template: ''
})


export abstract class ModelEditor extends Busyable implements OnInit, ComponentCanDeactivate {

    public ModelId: string = "";
    public Model: any | null = null;
    public ModelIsNew: boolean = false;
    public OriginalModelJson: string = "";
    public Form: FormGroup = new FormGroup({});
    public FormLoaded: boolean = false;
    public ValidatorLookup: any = {};
    public ReactiveFormsEnabled: boolean = true;

    public abstract DefaultModel(): any;
    public abstract AfterModelLoaded(): void;
    public abstract BeforeSave(): void;
    public abstract Validators(): any;
    public FormLabels(): any { }

    protected api: ApiServiceBase;
    protected parentRoute: ActivatedRoute;
    protected toast: ToastrService;
    protected modal: SimpleModalService;
    protected router: Router;

    constructor(@Inject('ModelUrl') private ModelUrl: string, injector: Injector) {
        super();
        this.api = injector.get(ApiServiceBase);
        this.parentRoute = injector.get(ActivatedRoute);
        this.toast = injector.get(ToastrService);
        this.modal = injector.get(SimpleModalService);
        this.router = injector.get(Router);

        this.parentRoute.params.subscribe((params :any) => { this.ModelId = params['id']; });

        ModelUrl = ModelUrl.trim();
        if (ModelUrl.endsWith("/")) {
            ModelUrl = ModelUrl.substring(0, ModelUrl.length - 1);
        }
        this.ValidatorLookup = this.Validators();
    }

    public ngOnInit(): void {

        this.api.loginService.LoginStatusChanged.subscribe(isLoggedIn => {
            if (this.Model == null) {
                this.InitializeModel();
            }
        });

        if (this.api.loginService.IsLoggedIn()) { //user is currently logged in
            this.InitializeModel();
        }
    }

    public InitializeModel() {
        this.ModelId = this.parentRoute.snapshot.params['id'];
        if (this.ModelId == 'new') {
            this.Model = this.DefaultModel();
            this.ModelIsNew = true;
            this.OriginalModelJson = JSON.stringify(this.Model);
            this.AfterModelLoaded_Internal();
        }
        else {
            this.FetchModel(this.ModelId);
        }
    }

    public FetchModel(id: string) {
        this.ModelId = id;
        this.Loading();
        let url = this.ModelUrl + (id != null ? '/' + id : "");
        this.api.Get(url).then(result => {
            this.Model = result;
            this.OriginalModelJson = JSON.stringify(this.Model);
            this.StopLoading();
            this.AfterModelLoaded_Internal();
        });
    }
    public AfterModelLoaded_Internal() {
        if (this.ReactiveFormsEnabled) {
            this.PopulateFormGroupFromModel(this.Model, this.Form, "");
            this.AddUILabelIntoForm();
        }
        this.AfterModelLoaded();
        if (this.ReactiveFormsEnabled) {
            this.FormLoaded = true;
        }
    }

    private AddUILabelIntoForm() {
        this.Form.addControl('FormLabels', new FormControl(this.FormLabels()))
    }

    public PopulateFormGroupFromModel(model: any, formGroup: FormGroup, path: string) {
        if (Array.isArray(model)) {
            for (let i of model) {
                this.PopulateFormGroupFromModel(i, formGroup, path);

            }
        }
        else {
            for (let prop in model) {
                let val = model[prop];
                let isArray = Array.isArray(val);
                let arrayTypeIsObject = isArray && val.length > 0 && (typeof val === 'object');
                if (prop == 'PolygonPoints' || prop == 'PolygonCenter' || prop == 'LocationPoints' || prop == 'Map' || prop == 'GeoLocationPoints' || prop == 'GeoPolygonPoints') {
                    //special handling for double[][] arrays of polygon points
                    let fc = new FormControl(val);
                    formGroup.addControl(prop, fc);
                    this.CheckForValidators(fc, path + prop);
                }
                else if (isArray && arrayTypeIsObject) {
                    //property type is array of objects
                    let fa = new FormArray([]);
                    formGroup.addControl(prop, fa);
                    (fa as any)._parent = formGroup;
                    for (let i = 0; i < val.length; i++) {
                        this.AddToFormArray(fa, val[i], path + prop);
                    }
                    fa.updateValueAndValidity();
                }
                else if (isArray) {
                    //it's an array of values
                    let fa = new FormArray([]);
                    formGroup.addControl(prop, fa);
                    for (let i = 0; i < val.length; i++) {
                        let fc = new FormControl(val);
                        fa.controls.push(fc);
                        fc.valueChanges.subscribe((val : any) => {
                            fa.updateValueAndValidity();
                        });
                    }
                    fa.updateValueAndValidity();
                }
                else {
                    let fc = new FormControl(model[prop]);
                    formGroup.addControl(prop, fc);
                    this.CheckForValidators(fc, path + prop);
                    fc.valueChanges.subscribe((val : any) => {
                        if (this.FormLoaded)
                            this.Form.markAsDirty();
                    });
                }
            }
        }
    }
    public AddToFormArray(fa: FormArray, model: any, path: string): FormGroup {
        let fg = new FormGroup({});
        (fg as any)._parent = fa;
        if (!path.endsWith('.')) path = path + "."; //populate function expects trailing dot to just add 
        fa.controls.push(fg);
        this.PopulateFormGroupFromModel(model, fg, path);
        fg.valueChanges.subscribe((val : any) => {
            fa.updateValueAndValidity();
        });
        if (this.FormLoaded) {
            fa.updateValueAndValidity();
            this.Form.markAsDirty();
        }
        return fg;
    }

    public CheckFormArrayContains(fa: FormArray, comparitor: string, value: string): boolean {
        var existing = fa.controls.map(x => x.get(comparitor)?.value);
        let exists = existing.some(x => x == value);
        return exists;
    }

    public RemoveFromFormArray(fa: FormArray, index: number) {
        fa.removeAt(index);
        if (this.FormLoaded) {
            this.Form.markAsDirty();
        }
    }

    private CheckForValidators(fc: FormControl, path: string) {
        if (this.ValidatorLookup != null && this.ValidatorLookup[path]) {
            let validators = this.ValidatorLookup[path];
            fc.addValidators(validators);
        }
    }

    public FormArray(parent: any, name: string): FormArray {
        let target = parent.controls[name];
        if (target instanceof FormArray) {
            return target as FormArray;
        }
        else {
            //property is not a formarray, probably was NULL instead of empty array when we got it initially so a formcontrol was created instead
            //make a new formarray and inject it into parent over the top of the old formcontrol
            let fa = new FormArray([]);
            parent.controls[name] = fa;
            (fa as any)._parent = parent;
            return fa;
        }
    }

    public Save() {
        if (!this.Form?.valid) {
            //form is not valid
            this.toast.warning("Form has missing or invalid data", "Error");

            return;
        }
        this.Busy();
        this.BeforeSave();

        let submitModel = this.ReactiveFormsEnabled ? this.Form.getRawValue() : this.Model;


        if (this.ModelIsNew) {
            this.Busy();
            this.api.Post<any>(this.ModelUrl, submitModel).then(result => {
                this.StopBusy();
                if (result != null) {
                    this.Model = result;
                    this.OriginalModelJson = JSON.stringify(this.Model);
                    this.toast.success('Item added successfully', 'Saved');
                    this.Form = new FormGroup({});
                    this.Form.markAsPristine();
                    this.ModelIsNew = false;
                    this.ModelId = result.Id;
                    this.AfterModelLoaded_Internal();
                    this.Form.markAsPristine();
                    this.AfterSave_Internal(this.ModelId);
                }

            })
        }
        else {
            this.Busy();
            let url = this.ModelUrl + (this.ModelId != null ? '/' + this.ModelId : "");
            this.api.Put(url, submitModel).then(result => {
                this.StopBusy();
                if (result != null) {
                    this.OriginalModelJson = JSON.stringify(this.Model);
                    this.toast.success('Item updated successfully', 'Saved');
                    // update the model
                    this.Model = result;
                    this.Form = new FormGroup({});
                    this.AfterModelLoaded_Internal();
                    this.AfterSave();
                    this.Form.markAsPristine();
                }
            });
        }

    }

    // private HandleApiError(err: any): ObservableInput<any> {
    //     if (err.error != null && err.error.Error != null) {
    //         this.toast.error(err.error.Error.Message, err.error.Error.ErrorType);
    //     }
    //     else if (err.error && err.error.errors && err.error.errors.id) {
    //         this.toast.error(err.error.errors.id[0], err.error.title);
    //     }
    //     else {
    //         this.toast.error('There was a problem', 'Error');
    //     }
    //     return of(null);
    // }

    public CanDeactivate() {
        if (this.ReactiveFormsEnabled) {
            return !this.Form.dirty;
        }
        return JSON.stringify(this.Model) == this.OriginalModelJson;
    }

    public SetDirty(form: any, control: any) {
        if (form != null) {

        }
    }

    public ShowDebugMessages() {
        this.modal.addModal(HardwaremessagesviewerComponent, { DeviceId: this.ModelId });
    }

    public GenerateIconName(fg: FormGroup | AbstractControl, marker: boolean = false, markerselected: boolean = false): string {
        return Deviceicons.GenerateIconName(fg, marker, markerselected);
    }

    public AfterSave(): void{
        //do nothing
    }

    public AfterSave_Internal(ModelId : string): void{
        var routerlink = this.router.url;
        routerlink = routerlink.replace('new', ModelId);
        this.router.navigate([routerlink]);
    }
}
