import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/components/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { FeaturesService } from 'src/app/Services/features.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editrole',
  templateUrl: './editrole.component.html',
  styleUrls: ['./editrole.component.scss']
})
export class EditRoleComponent extends ModelEditor implements OnInit {
  public features: any[] = [];
  public endUserfeatures: any[] = [];
  public Organizations: any[] = [];
  public boolExpandAll: boolean = false;
  public expandSelected: boolean = false;
  public OrganizationName : any = "";
  public SelectedFeatureName = "";
  public SelectedFeatureDefinition = "";
  public SelectedFeatureRoutes: any[] = [];
  public SelectedFeaturePrerequisites: any[] = [];
  public newRole : boolean = false;

  constructor(private apiService: ApiServiceBase, private injector: Injector, private route: ActivatedRoute, private featureService: FeaturesService) {
      super("master/roles", injector);
  }

  public IsAdminPermissionSelected(item: any): boolean {
      var grantedPermissions = this.Form.get("RolePermissions")?.value;
      if (grantedPermissions == null) {
        return false;
      }
      var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
      if (t.length > 0) {
        return true;
      }
      else {
        return false;
      }
    }

    
  public ClickItem(item: any) {
    this.SelectedFeatureDefinition = item.Description;
    this.SelectedFeatureName = item.Name;
    this.SelectedFeatureRoutes = item.AdminMenuItems;
    this.SelectedFeaturePrerequisites = item.PrerequisiteFeatures;
  }
    
  public UpdateAdminSelectedItem(item: any) {
    var x = this.IsAdminPermissionSelected(item);
      if (x) 
      {
        item.IsSelected = false;
        //item exists in list, so remove.
        var g = this.Form.get("RolePermissions")?.value;
        var index = g.findIndex((x: any) => x.Permission == item.ClassName);
        this.FormArray(this.Form, "RolePermissions").removeAt(index);

        //remove parents
        this.RemoveParentAdminPermission(item);
      }
      else {


        //item does not exist in list, so add.
        var RolePermission = {
          Permission: item.ClassName,
          PermissionLevel: item.GrantableAdminPermissionLevels == null || item.GrantableAdminPermissionLevels.length == 0
          ? 0 : item.GrantableAdminPermissionLevels[0].Level,
        }
        item.IsSelected = true;
        this.AddToFormArray(this.FormArray(this.Form, "RolePermissions"), RolePermission, "RolePermissions");

        //add parents to list
        this.AddParentAdminPermission(item);
        //add prerequisites
        this.AddPrerequisiteAdminPermissions(item);
      }    
      this.Form.markAsDirty();
      this.ProcessRoleFeatures();
    };

    public AddParentAdminPermission(item: any) {
      if(item.Parent != null)
        {
          var parent = item.Parent;
          var isParentSelected = this.IsAdminPermissionSelected(parent);
          
          if(!isParentSelected){
          var RolePermission = {
            Permission: parent.ClassName,
            PermissionLevel: parent.GrantableAdminPermissionLevels == null || parent.GrantableAdminPermissionLevels.length == 0
            ? 0 : parent.GrantableAdminPermissionLevels[0].Level,
          }
          
          parent.IsSelected = true;
          this.AddToFormArray(this.FormArray(this.Form, "RolePermissions"), RolePermission, "RolePermissions");
        }          
          this.AddPrerequisiteAdminPermissions(parent);
          this.AddParentAdminPermission(parent);
        }
    }

    public RemoveParentAdminPermission(item: any) {
      if(item.Parent != null)
      {
        var parent = item.Parent;
        var isParentSelected = this.IsAdminPermissionSelected(parent);

        if (isParentSelected) {
          parent.HasChildItemsSelected = parent.Children.filter((x: any) => x.IsSelected == true).length > 0;
          parent.IsSelected = parent.HasChildItemsSelected;

          if(!parent.IsSelected){
            var g = this.Form.get("RolePermissions")?.value;
            var index = g.findIndex((x: any) => x.Permission == parent.ClassName);
            this.FormArray(this.Form, "RolePermissions").removeAt(index);
            this.RemoveParentAdminPermission(parent);
          }
        }
      }
    }

    public AddPrerequisiteAdminPermissions(item: any){
      if(item.PrerequisiteFeatures != null && item.PrerequisiteFeatures.length > 0){
        item.PrerequisiteFeatures.forEach((prereq : any) => {
          //add and select prereq
          prereq.ClassName = prereq.Name;
          var isPrereqSelected = this.IsAdminPermissionSelected(prereq);
          
          if(!isPrereqSelected)
          {
            var allFeatures = this.featureService.GetFeatures();
            var preI = allFeatures.findIndex((x: any) => x.ClassName == prereq.Name);
            var pre = allFeatures[preI];
            var RolePermission = {
              Permission: pre.ClassName,
              PermissionLevel: pre.GrantableAdminPermissionLevels == null || pre.GrantableAdminPermissionLevels.length == 0
              ? 0 : pre.GrantableAdminPermissionLevels[0].Level,
            }

            prereq.IsSelected = true;
            this.AddPrerequisiteAdminPermissions(pre);
            this.AddParentAdminPermission(pre);
            this.AddToFormArray(this.FormArray(this.Form, "RolePermissions"), RolePermission, "RolePermissions");
            };
          });
        }
        this.Form.markAsDirty();
        this.ProcessRoleFeatures();
    }

    public UpdateAdminItemSelectedValue(item: any) {
      //item exists in list, so remove.
      var g = this.Form.get("RolePermissions")?.value;
      var indexItem = g.find((x: any) => x.Permission == item.ClassName);
      var index = g.findIndex((x: any) => x.Permission == item.ClassName)
      this.FormArray(this.Form, "RolePermissions").removeAt(index);
      indexItem.PermissionLevel = item.SelectItemValue;

      var RolePermission = {
        Permission: indexItem.Permission,
        PermissionLevel: indexItem.PermissionLevel,
      }
      this.AddToFormArray(this.FormArray(this.Form, "RolePermissions"), RolePermission, "RolePermissions");
      this.Form.markAsDirty();
      this.ProcessRoleFeatures();
  };


  public GrantedAdminPermissionLevel(item: any): number {
      var grantedPermissions = this.Form.get("RolePermissions")?.value;
      if (grantedPermissions == null) {
        return 0;
      }
      var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
      if (t.length > 0) {
        return t[0].PermissionLevel;
      }
      else {
        return 0;
      }
    }

    
  public ExpandAll(){
    this.boolExpandAll = true;
    this.ProcessRoleFeatures();
  }

  public CollapseAll(){
    this.boolExpandAll = false;
    this.expandSelected = false;
    this.ProcessRoleFeatures();
  }

  public ExpandSelected(){
    this.boolExpandAll = false;
    this.expandSelected = true;
    this.ProcessRoleFeatures();
  }

    public ProcessRoleFeatures(){
      this.features.forEach((element: any) => {
        element.Parent = null;
        element.IsSelected = this.IsAdminPermissionSelected(element);
        element.Expanded = this.boolExpandAll || (this.expandSelected && element.HasChildItemsSelected);
        element.SelectItems = element.GrantableAdminPermissionLevels;
        element.SelectItemValue = this.GrantedAdminPermissionLevel(element);
        element.HasCheckbox = true;
        element.Children?.forEach((child: any) => {
          child.Parent = element;
          child.Expanded = this.boolExpandAll || (this.expandSelected && child.HasChildItemsSelected);
          child.IsSelected = this.IsAdminPermissionSelected(child);
          child.SelectItems = child.GrantableAdminPermissionLevels;
          child.SelectItemValue = this.GrantedAdminPermissionLevel(child);
          child.HasCheckbox = true;
          child.Children?.forEach((c: any) => {
            c.Parent = child;
            c.Expanded = this.boolExpandAll || (this.expandSelected && c.HasChildItemsSelected);
            c.IsSelected = this.IsAdminPermissionSelected(c);
            c.SelectItems = c.GrantableAdminPermissionLevels;
            c.SelectItemValue = this.GrantedAdminPermissionLevel(c);
            c.HasCheckbox = true;
          });
          child.HasChildItemsSelected = child.Children.filter((x: any) => x.IsSelected == true).length > 0;
        });
        element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y : any) => y.IsSelected).length > 0).length > 0;
      });
    }

  public ChangeOrgSelected(evt : any){
    this.Model.OrganizationId = evt.target.value;
    this.Model.OrganizationName = this.Organizations.filter(x => x.Id == evt.target.value)[0].Name;
    this.Form.get("OrganizationId")?.setValue(evt.target.value);
    this.Form.get("OrganizationName")?.setValue(this.Model.OrganizationName);

    this.apiService.Get<any>("master/features/installed/" + this.Model.OrganizationId).then(result => {
      this.features = result;
      this.ProcessRoleFeatures();
    });
  }
    
  public AfterModelLoaded(): void {   
    this.Loading();
    this.Model.OrganizationName = "";

    var orgId = this.route.snapshot.queryParams['OrganizationId'];
    if(this.Model.OrganizationId == null && orgId != null){
      this.Model.OrganizationId = orgId;
      this.Form.get('OrganizationId')?.setValue(orgId);
    }

    if (this.Model.OrganizationId == null) {
      this.apiService.Get<any>("master/organizations").then(result => {
        this.Organizations = result;
        this.StopLoading();
      });
      this.newRole = true;
      this.features = [];
    } else {
      this.apiService.Get<any>("master/organizations/" + this.Model.OrganizationId).then(result => {
        this.Model.OrganizationName = result.Name;
        this.StopLoading();
      });
      this.apiService.Get<any>("master/features/installed/" + this.Model.OrganizationId).then(result => {
        this.features = result;
        this.newRole = false;
        this.ProcessRoleFeatures();
      });
    }
  }

  public BeforeSave(): void {
  }

  public Validators() {
  }
  
  public override DefaultModel(): any {
    return { Name: "New Role", RolePermissions: [], OrganizationId: null}
  }
}
