import { environment } from "src/environments/environment";
import { LoginService } from "../auth/login.service";
import { ColorService } from "../Services/color.service";
import { Deviceicons } from "../util/deviceicons";

export class Datalistviewcolumn {
    public Header: string = "";
    public Property: string = "";
    public MediaId: string = "";
    public Width: string = "";
    public MaxLength: number = Number.MAX_VALUE;
    constructor(header?: string, property?: string, maxLength?: number) {
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (maxLength != null)
            this.MaxLength = maxLength;
    }

    public Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if (this.MaxLength != Number.MAX_VALUE) {
            return item[this.Property].substring(0, this.MaxLength) + "...";

        } else {
            return item[this.Property];
        }
    }
}


export class BooleanViewColumn extends Datalistviewcolumn {
    public ShowOnlyTrueValues: boolean = false;
    constructor(header?: string, property?: string, ShowOnlyTrueValues? : boolean) {
        super(header);

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (ShowOnlyTrueValues != null)
            this.ShowOnlyTrueValues = ShowOnlyTrueValues;
    }

    public override Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if (this.MaxLength != Number.MAX_VALUE) {
            return item[this.Property].substring(0, this.MaxLength) + "...";

        } 
        else {
            let result = item[this.Property];
            if(this.ShowOnlyTrueValues){
                if(result == true){
                    return '<p style="color: green;"><b>&#x2713;</b></p>';
                }
            }
            else{
                return(result == true ? '<p style="color: green;"><b>&#x2713;</b></p>' : '<p style="color: red;"><b>&#x2715;</b></p>' );
            }
        }
        return "";
    }
}

export class BooleanTextViewColumn extends Datalistviewcolumn {
    public values: string[] = [];
    constructor(header?: string, property?: string, values?: string[]) {
        super(header);

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (values != null)
            this.values = values;
    }

    public override Render(item: any): string {
        if (item[this.Property] == null || item[this.Property] == undefined){
            return '';
        }

        if(item[this.Property] == true){
            return this.values[0];
        }
        if(item[this.Property] == false){
            return this.values[1];
        }

        return "";
    }
}

export class DeviceIconcolumn extends Datalistviewcolumn {
    constructor(header?: string) {
        super(header);
    }

    public override Render(item: any): string {
        return '<img style="vertical-align: middle; height: 1.6em;" src="' + Deviceicons.GenerateIconName(item, false) + '"/>';
    }
}

export class DatalistRawImageColumn extends Datalistviewcolumn {
    constructor(header?: string, property?: string) {
        super(header, property);
    }

    public override Render(item: any): string {
        return '<img style="vertical-align: middle; height:40px; width:40px" src="data:image/' + this.getImageTag(item[this.Property]) + ';base64,' + item[this.Property] + '"/>'
    }

    private getImageTag(imageBase64: any): string {
        let extension: string = '';
        const decodedString = window.atob(imageBase64);
        const lowerCase = decodedString.toLocaleLowerCase();
        if (lowerCase.indexOf("png") !== -1) extension = "png";
        else if (lowerCase.indexOf("svg") !== -1) extension = "svg+xml";
        else if (lowerCase.indexOf("jpg") !== -1 || lowerCase.indexOf("jpeg") !== -1) extension = "jpg";
        return extension;

    }

}

export class ColorDisplaycolumn extends Datalistviewcolumn {
    public token : string | undefined = "";
    constructor(header?: string, property?: string, width?: string, media?: string, loginService?: LoginService) {
        super();

        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if (width != null)
            this.Width = width;
        if (media != null){
            this.MediaId = media;
            if (loginService != null)
                this.token = encodeURIComponent(loginService.CurrentToken())
        }
    }

    public override Render(item: any): string {
        var colorDiv = '<div style="background-color:#' + item[this.Property].replace("#","") + '; width:5em; vertical-align: middle; color:white; text-align:center; height: 1.6em;">'
        if(this.MediaId != "")
            colorDiv = colorDiv + '<img style="height:1.6em; width:1.6em;" src="'+ environment.serverBaseUrl + '/media/' + item[this.MediaId] + '/file?token=' + this.token + '">'
        colorDiv = colorDiv + '</>'
        return colorDiv;
    }
}


export class PercentagesDisplaycolumn extends Datalistviewcolumn {
    public colorDivs: string  = "";
    public cService;
    constructor(header?: string, property?: string, colorService?: ColorService) {
        super();
        if (header != null)
            this.Header = header;
        if (property != null)
            this.Property = property;
        if(colorService != null){
            this.cService = colorService;
        }
    }

    public override Render(item: any): string {
        this.colorDivs = '<div style="width: 100%;">'
        var items = item[this.Property];
        items.forEach((element: any, index: any) => {
            if(index != (items.length-1))
                element.displayText = element.ThresholdPercent + '% - ' + (items[index+1].ThresholdPercent-1) + '%';
            if(index == (items.length-1))
                element.displayText = element.ThresholdPercent + '% - 100%';
                element.TextClass = this.cService?.GetVisibleTextClassFor(element.Color)

            this.colorDivs = this.colorDivs + '<div class="' + element.TextClass+ '" style="float:left; background-color:#' + element.Color.replace("#","") + '; width:7em; margin-right: 10px; vertical-align: middle; border: 1px solid black; color:white; text-align:center; height: 1.6em; border-radius:5px;">' + element.displayText + '</div>'
        });
        this.colorDivs = this.colorDivs + '</div>'
        return this.colorDivs;
    }
}

export class DatalistviewPercentColumn extends Datalistviewcolumn {
    public DividendProperty: string = ""; //the total number
    public DivisorProperty: string = ""; //the smaller number to calculat % of Dividend
    constructor(header?: string, dividendProperty?: string, divisorProperty?: string) {
        super(header);
        if (dividendProperty != null)
            this.DividendProperty = dividendProperty;
        if (divisorProperty != null)
            this.DivisorProperty = divisorProperty;
    }

    public override Render(item: any): string {
        let dividend = item[this.DividendProperty];
        let divisor = item[this.DivisorProperty];
        if (dividend == 0) {
            return "";
        }
        try {
            let percent = Math.round((divisor / dividend) * 100);
            if (percent > 100) {percent = 100;}
            if (percent < 0) {percent = 0;}
            return percent + "%";
        }
        catch (ex) {
            return "-";
        }

    }
}
