<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
  <app-pageheader [Title]="'Organization: ' + this.Form.get('Name')?.value + ' (' + this.Form.get('HostName')?.value + ')'">
    <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
  </app-pageheader>
<article class="panel">
<app-tabs>
  <app-tab Title="Organization Information">
      <div class="columns">
          <div class="column is-6">
              <app-field Label="Id">
                  <div class="control">
                      <input class="input" [value]="this.Form.get('Id')?.value" disabled/>
                  </div>
              </app-field>
              <app-field Label="Name">
                <div class="control">
                  <input formControlName="Name" class="input" type="text" name="Name" />
                  <app-validator [For]="this.Form.get('Name')"></app-validator>
                </div>
              </app-field>
              <app-field Label="Short Id">
                  <div class="control">
                      <input class="input" [value]="this.Form.get('ShortId')?.value" disabled/>
                  </div>
              </app-field>
              <app-field Label="City">
                  <div class="control">
                      <input class="input" [value]="this.Form.get('City')?.value" disabled/>
                  </div>
              </app-field>
              <app-field Label="TimeZone">
                  <div class="control">
                    <select class="input minimal" (change)="TimeZoneChanged($event)" formControlName="TimeZoneId">
                        <option *ngFor="let i of TimeZones" [value]="i.Id">{{i.Name}}
                        </option>
                    </select>
                      <!-- <input class="input" [value]="this.Form.get('TimeZoneDisplayName')?.value" disabled/> -->
                  </div>
              </app-field>
              <app-field Label="Currency" [HasAddons]="true">
                <div class="control">
                  <select class="input minimal" (change)="CurrencyChanged($event)" formControlName="CurrencyCode">
                      <option *ngFor="let i of Currencies" [value]="i.Code">{{i.Description}}
                      </option>
                  </select>
                </div>   
                <div class="control">
                  <a class="button is-light">{{SelectedCurrency?.Code}} ({{SelectedCurrency?.Symbol}})</a>
                </div>
            </app-field>
            <app-field Label="Log Level">
              <div class="control select">
                <select class="input select" formControlName="LogLevel">
                  <option value="null" hidden>Info</option>
                  <option value="" hidden>Info</option>
                  <option *ngFor="let level of LogLevels" [value]="level.Value">{{level.Name}}</option>
                </select>
              </div>
            </app-field>
            
          </div>
          <div class="column is-6">
            <app-map-input MapWidth="100%" formControlName="LocationPoints" DefaultMode="marker"></app-map-input>
          </div>
      </div>
  </app-tab>
  <app-tab Title="Enabled Feature Tree">
    <div class="columns">
      <div class="column is-5">
        <div style="margin-bottom:10px;">
          <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
          <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
          <a (click)="ExpandSelected()" >Expand Selected</a></span>
        </div>
        <div style="padding-left:50px; margin-bottom:20px;">
          <app-tree>
            <app-treenoderecursive [recursiveList]="features" (UpdateItem)="UpdateSelectedItem($event)" (ItemClicked)="ClickItem($event)">
            </app-treenoderecursive>
          </app-tree>
        </div>
      </div>
      <div class="column is-7" style="border-color:black">
        <div class="box content" style="height:100%">
          <h3 class="has-text-weight-bold">{{SelectedFeatureName}}</h3>
          <p class="has-text-weight-light- is-italic">{{SelectedFeatureDefinition}}</p>
          <h6 *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">Enables Menu Items</h6>
          <ul *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">
            <li *ngFor="let i of SelectedFeatureRoutes">
              {{i.MenuPath}}
            </li>
          </ul>
          <h6 *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0">Feature Prerequisites</h6>
          <ul *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0"> 
            <li *ngFor="let i of SelectedFeaturePrerequisites">
              {{i.Name}}
            </li>
          </ul>
        </div>
      </div>
    </div>
</app-tab>
</app-tabs>
</article>