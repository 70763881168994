import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Datalistrequestfilter } from '../../datalistrequestfilter';
import { AdminFilterComponent } from '../admin-filter/admin-filter.component';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { IFilter, isFilterFilters, isSearchTermFilter, isSubsetFilter } from '../ifilter';
import { PropertyFilterComponent } from '../property-filter/property-filter.component';
import { RatesFilterComponent } from '../rates-filter/rates-filter.component';
import { SearchButtonComponent } from '../search-button/search-button.component';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { SubsetFilterComponent } from '../subset-filter/subset-filter.component';

@Component({
  selector: 'app-filter-set',
  templateUrl: './filter-set.component.html',
  styleUrls: ['./filter-set.component.scss']
})
export class FilterSetComponent implements AfterContentInit, OnInit {

  @Input()
  public title: string | undefined;
  
  @Input()
  public subSection = false;

  @Output()
  public applySearch: EventEmitter<any> = new EventEmitter();


  constructor() { }
  ngOnInit(): void {
    
  }


  search() {
    console.log("raise search from filter set");

    this.applySearch.emit(this.getFilterValues());
  }

  ngAfterContentInit(): void {
    console.log("After content init");
    if (this.SearchButton != undefined) {
      console.log("Sub search from filter set");

      this.SearchButton.applySearch.subscribe(() => {
        this.search();
      });
    }
    this.loadAllFilters(this);
  }




  loadAllFilters(filterSet: FilterSetComponent) {
    if (filterSet.DateFilter != undefined) {

      console.log("filter set adding datefilers");
      this.filters.push(filterSet.DateFilter);
    }

    if (filterSet.RatesFilter != undefined) {
      console.log("filter set adding ratesfilers");
      this.filters.push(filterSet.RatesFilter);
    }

    if (filterSet.SearchTermFilter != undefined) {
      console.log("adding search term");
      this.filters.push(filterSet.SearchTermFilter)
    }

    if (filterSet.PropertyFilter != undefined) {
      console.log("adding property filter");
      this.filters.push(filterSet.PropertyFilter)
    }

    if (filterSet.SubSetFilter != undefined) {
      console.log("adding subet term");
      this.filters.push(filterSet.SubSetFilter)
    }

    if (filterSet.AdminFilter != undefined) {
      console.log("adding admin filter");
      this.filters.push(filterSet.AdminFilter);

    }
  }

  public Filter = {
    PageNumber: 1,
    PageSize: 15,
    SearchTerm: "",
    Subset: "",
    Filters: [] as Array<Datalistrequestfilter>
  };

  private filters: IFilter[] = [];

  @ContentChild(DateFilterComponent)
  public DateFilter?: DateFilterComponent;

  @ContentChild(RatesFilterComponent)
  public RatesFilter?: RatesFilterComponent;

  @ContentChild(SearchFilterComponent)
  public SearchTermFilter?: SearchFilterComponent;

  @ContentChild(SubsetFilterComponent)
  public SubSetFilter?: SubsetFilterComponent;

  @ContentChild(AdminFilterComponent)
  public AdminFilter?: AdminFilterComponent;

  @ContentChild(FilterSetComponent)
  public AdvancedFilterSet?: FilterSetComponent;

  @ContentChild(SearchButtonComponent)
  public SearchButton?: SearchButtonComponent;

  @ContentChild(PropertyFilterComponent)
  public PropertyFilter?: PropertyFilterComponent;


  public getFilterValues() {
    this.Filter.Filters = [];
    this.filters.forEach(element => {

      if (isSearchTermFilter(element)) {
        this.Filter.SearchTerm = element.SearchTerm.trim();
      }
      if (isSubsetFilter(element)) {
        this.Filter.Subset = element.Subset.trim();
      }
      if (isFilterFilters(element)) {
        this.Filter.Filters = this.Filter.Filters.concat(element.Filters);
      }
    });

    return this.Filter;
  }
}
