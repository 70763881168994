<form onsubmit="Login()" (keypress)="KeyPress($event)">
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label has-text-light">Email</label>
        </div>
        <div class="field-body">
            <div class="field">
            <div class="control">
                <input name="email" class="input" type="text" [(ngModel)]="user.username" />
            </div>
            </div>
        </div>  
    </div>
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label has-text-light">Password</label>
        </div>
        <div class="field-body">
            <div class="field">
            <div class="control">
                <input name="password" class="input" type="password" [(ngModel)]="user.password"/>
            </div>
            </div>
        </div>  
    </div>
    <div class="has-text-light has-text-centered" *ngIf="ErrorMessage != ''">
        {{ErrorMessage}}
    </div>
    <div class="has-text-centered">
        <actionbutton [Parent]="this" Icon="chevron-right" (click)="Login()">Log In</actionbutton>
    </div>

</form>