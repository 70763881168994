import { Component, Injector, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/components/modeleditor';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, Validators } from '@angular/forms';
import { FeaturesService } from 'src/app/Services/features.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent extends ModelEditor implements OnInit {
  constructor(private apiService: ApiServiceBase, public featuresService: FeaturesService, private injector: Injector) {
    super("master/users", injector);
  }

  public override DefaultModel(): void {
  }

  public override AfterModelLoaded(): void {

  }
  public override BeforeSave(): void {

  }

  public override Validators(): any {
    return {
      'Name': [Validators.required]
    };
  }

  public SetIsMasterAdmin(evt:any) {
    this.Model.AllowMasterAccess = evt.target.checked;
  }

  public RemoveOrganizationUser(index: number) {
    this.Model.OrganizationUsers.splice(index, 1);
    this.FormArray(this.Form, "OrganizationUsers").removeAt(index);
    this.Form.markAsDirty();
  }

  public ActivateAccount(){
    this.apiService.Post<any>('master/users/' + this.Model.Id + "/activate", null).then((result: any) => {
      this.Model = result;
    })
  }
}
