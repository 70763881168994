import { Component, DoCheck, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements DoCheck {

  @Input()
  public For : any;
  @Input()
  ErrorDefs : any;
  public ErrorMessage : string = '';
  
  ngDoCheck(): void {
    this.ErrorMessage = '';
    if(this.For == null){
      return;
    }

    if(this.For != null && this.For.errors){
      //reactive forms validation
      for(let prop in this.For.errors){
        if(this.ErrorMessage != '') this.ErrorMessage += ", ";
        switch(prop){
          case 'required':
            this.ErrorMessage += "Required";
            break;
          case 'min':
            this.ErrorMessage += "Value must be at least " + this.For.errors[prop].min;
            break;
          case 'max':
            this.ErrorMessage += "Value must be less than " + this.For.errors[prop].max;
            break;
          default: 
            this.ErrorMessage += "Error";
        }
      }
    }
    else if(this.For.validity){
      Object.keys(this.ErrorDefs).some((key : any) => {
        if (this.For.validity[key] == true) {
          this.ErrorMessage = this.ErrorDefs[key];
          return true;
        }
        return false;
      });
    }
      
    
  }

}
