import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Busyable } from 'src/app/components/busyable';
import { Datalistrequestfilter } from 'src/app/components/datalistrequestfilter';
import { BooleanViewColumn, Datalistviewcolumn } from 'src/app/components/datalistviewcolumn';
import { ModelEditor } from 'src/app/components/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { Loader } from 'three';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent extends Busyable implements OnInit  {

  public ModelId: any;
  public orgInfo: any;
  public editPage: any;
  public features: any[] = [];
  public markers: any[] = [];
  public ShowAlertsDropdown: boolean = false;
  public FormLoaded: boolean = false;
  public routerLink: string = "";

 constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, private router: Router) {
    super();
   }

   
  public MapOptions: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4,
  };


  public AddOrganizationAccess(){
      this.router.navigate(['/users//00000000-0000-0000-0000-000000000000/organizationuser/edit/new'], {queryParams : {"OrganizationId": this.orgInfo.Id}});
  }

  public AddRole(){
    this.router.navigate(['roles/edit/new'], {queryParams : {"OrganizationId": this.orgInfo.Id}});
  }


  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe((params :any) => { this.ModelId = params['id']; });
    this.editPage = this.parentRoute + "/edit";

    this.apiService.Get<any>("master/organizations/" + this.ModelId).then(result => {
      this.orgInfo = result;

      this.markers.push({
        position: {
          lat: this.orgInfo.LocationPoints[1],
          lng: this.orgInfo.LocationPoints[0]
        }});

        this.MapOptions.center = {lat: this.orgInfo.LocationPoints[1], lng: this.orgInfo.LocationPoints[0]};

      this.ProcessFeatures();
      this.FormLoaded = true;
      this.StopLoading();
    });

    this.apiService.Get<any>("features/all").then(result => {
      this.features = result;
    });
  }

  public RoleColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Organization", "OrganizationName")
  ];

  public UserColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("UserId", "Id"),
    new Datalistviewcolumn("First Name", "FirstName"),
    new Datalistviewcolumn("LastName", "LastName"),
    new Datalistviewcolumn("Email", "Email"),
    new BooleanViewColumn("Admin Panel Access", "IsAdmin"),
    new BooleanViewColumn("IsActive", "IsActive", true),
    new Datalistviewcolumn("Last Sign In", "LastSignedInOnFriendlyString"),
  ];

  public IsSelected(item: any): boolean {
    var installedFeatures = this.orgInfo.Features;
    if (installedFeatures == null) {
      return false;
    }
    var t = installedFeatures.filter((x: any) => x.Name == item.ClassName);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }


  public ProcessFeatures(){
    this.features.forEach((element: any) => {
      element.IsSelected = this.IsSelected(element);
      element.HasCheckbox = true;
      element.Expanded = true
      element.Children?.forEach((child: any) => {
        child.IsSelected = this.IsSelected(child);
        child.HasCheckbox = true;
        child.Expanded = true;
        child.Children?.forEach((c: any) => {
          c.Expanded = true;
          c.IsSelected = this.IsSelected(c);
          c.HasCheckbox = true;
        });
        child.HasChildItemsSelected = child.Children.filter((x: any) => x.IsSelected == true).length > 0;
      });
      element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y : any) => y.IsSelected).length > 0).length > 0;
    });
  }
}
