import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Busyable } from '../../busyable';

@Component({
  selector: 'app-datalist-add-button',
  templateUrl: './datalist-add-button.component.html',
  styleUrls: ['./datalist-add-button.component.scss']
})

export class DataListAddButton implements OnInit {

  @Input()
  public AddRoute : string | undefined;
  @Input()
  public ButtonType: string = "button";
  @Input()
  public ButtonText: string = "Add New";
  
  @Input()
  public Icon: string = "plus";

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public Clicked(evt: any) {
    this.router.navigate([this.AddRoute]);
  }
}
