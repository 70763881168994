import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/components/datalistviewcolumn';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  
  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("TimeZone", "TimeZoneDisplayName")
  ];

  ngOnInit(): void {
  }


}
