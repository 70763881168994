<div ace-editor
[(text)]="Model" 
[mode]="Mode" 
[theme]="'chrome'"
[options]="StyleEditorOptions"
[readOnly]="false"
[autoUpdateContent]="true" 
[durationBeforeCallback]="1000" 
style="min-height: 200px; width:100%; overflow: auto;"
(textChanged)="OnChange($event)"
></div>