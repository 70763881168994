import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Datalistviewcolumn } from 'src/app/components/datalistviewcolumn';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { AddRoleUserComponent } from '../addroleuser/addroleuser.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { DatalistviewComponent } from 'src/app/components/datalistview/datalistview.component';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {

  @ViewChild(DatalistviewComponent) listView: DatalistviewComponent | undefined

  public ModelId: any;
  public roleInfo: any;
  public editPage: any;
  public ShowAlertsDropdown: boolean = false;
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute, private modalService: SimpleModalService) { }

  ngOnInit(): void {
    this.parentRoute.params.subscribe((params : any) => { this.ModelId = params['id']; });
    this.editPage = this.parentRoute + "/edit";

    this.apiService.Get<any>("master/roles/" + this.ModelId).then(result => {
      this.roleInfo = result;
    });
    
  }

  public showAddRoleUserModal() {
    this.modalService.addModal(AddRoleUserComponent, { RoleId: this.roleInfo.Id }).subscribe(result => 
      { 
        this.listView?.Refresh();
      });
  }

    
  public Columns: Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("First Name", "FirstName"),
    new Datalistviewcolumn("Last Name", "LastName"),
    new Datalistviewcolumn("Email", "Email")
  ];
}
