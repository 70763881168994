import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-userselector',
  templateUrl: './userselector.component.html',
  styleUrls: ['./userselector.component.scss']
})
export class UserselectorComponent implements OnInit, ControlValueAccessor, OnChanges {

  public UserId: string | null = null;
  @Output()
  public UserIdChange = new EventEmitter<string | null>();

  public InitialString: string = "";
  @Input()
  public User: any = { Email: "", FirstName: "", LastName: "" };

  @Input()
  public CanClear: boolean = true;

  public SearchResults: any[] = [];
  public SearchTerm: string = "Email";
  public IsLoading: boolean = false;

  constructor(private apiService: ApiServiceBase, @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.UserId = this.User.Id;
    this.onChange(this.User.Id);
    this.UserIdChange.emit(this.User);
  }

  ngOnInit(): void {
  }

  public writeValue(id: string | null): void {
    this.UserId = id;
    if (this.UserId == "") this.User = null;
  }
  public onChange = (id: string | null) => { };
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public onTouched = () => { };
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  public ItemSelected(item: any) {
    if (item && item != null && item.Id) {
      this.User = { Id: item.Id, FirstName: item.FirstName, LastName: item.LastName, Email: item.Email };
      this.UserId = item.Id;
      this.onChange(item.Id);
      this.UserIdChange.emit(this.User);
    }
  }
  public SearchChanged(val: string) {
    console.log("User Selector search event: '" + val + "'");
    if (val == null || val == "") return;
    this.User = null;
    this.IsLoading = true;
    this.apiService.Post<any>("master/users/searches", { PageNumber: 1, PageSize: 8, SearchTerm: val }).then(result => {
      this.SearchResults = result.Results;
      this.IsLoading = false;
    });
  }
  public ClearUser() {
    this.UserId = null;
    this.onChange(null);
  }
  public Cleared() {
    this.UserId = null;
    this.onChange(null);
  }
  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
      console.log("intercepted enter key on user selector");
    }
  }
  public InputKeyPress(evt: any) {

  }

}
