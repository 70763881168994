import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { DateRangeComponent, DisplayDate } from '../../inputs/daterange/daterange.component';
import { IFilter, IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-date-filter]',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  host: {'class': 'control select'}
})
export class DateFilterComponent implements OnInit, IFilterFilters {

  constructor(private modalService: SimpleModalService, private localStorageService: LocalStorageService, private router: Router) {

    this.localStorageKey = this.router.url + '-' + this.localStorageKey;
  }

  public SelectedSearchDate: any = "today,tomorrow";
  public DateSearchDates: any;
  public CustomVal = "Custom";
  private localStorageKey = "Data_View_Filter";

  @Input()
  public DateSearchProperty: string = "";


  public Filters: any[] = [];

  ngOnInit(): void {
    let storedFilter = this.localStorageService.Get(this.localStorageKey);
    if (storedFilter != null) {
      storedFilter.Filters;
      this.applySavedDateSelection(storedFilter.Filters);
    }


    this.getLocalDates();
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    // init value to today 
    // let start = (params[this.DateSearchProperty] != null ? params[this.DateSearchProperty].split(",")[0] : "today");
    // let end = (params[this.DateSearchProperty] != null ? params[this.DateSearchProperty].split(",")[1] : "tomorrow");

    let start = this.SelectedSearchDate.split(",")[0];
    let end = this.SelectedSearchDate.split(",")[1]
    this.Filters.push({
      Property: this.DateSearchProperty,
      Comparator: ">=",
      Value: start
    })
    this.Filters.push({
      Property: this.DateSearchProperty,
      Comparator: "<=",
      Value: end
    })
  }

  applySavedDateSelection(filters: any) {
    let dateFilters = filters.filter((x: any) => x.Property == this.DateSearchProperty);
    if (dateFilters != null) {
      if (dateFilters.length == 2) {

        let startDate = new Date();
        let endDate = new Date();

        if (dateFilters[0].Value.toLowerCase().includes('local')) {
          // this is a custom date
          this.SelectedSearchDate = "custom"
          if (dateFilters[0].Comparator == ">=") {
            startDate = new Date(dateFilters[0].Value.replace('local', ''));
            endDate = new Date(dateFilters[1].Value.replace('local', ''));
          } else {
            startDate = new Date(dateFilters[1].Value.replace('local', ''));
            endDate = new Date(dateFilters[0].Value.replace('local', ''));
          }

          this.CustomVal = "Custom (" + DisplayDate(startDate) + "-" + DisplayDate(endDate) + ")";
        } else {

          if (dateFilters[0].Comparator == ">=") {
            this.SelectedSearchDate = dateFilters[0].Value + ',' + dateFilters[1].Value
          } else {
            this.SelectedSearchDate = dateFilters[1].Value + ',' + dateFilters[2].Value
          }
        }


      }
      console.log(this.SelectedSearchDate);

    }
  }


  public addDateFilter(event: any) {
    console.log("Add Date Filter");
    this.Filters = [];

    var val = event.target.value;

    if (val == "custom") {
      this.modalService.addModal(DateRangeComponent, {})
        .subscribe((result) => {
          if (result != null) {
            this.CustomVal = "Custom (" + result.Display + ")";

            this.Filters.push({
              Property: this.DateSearchProperty,
              Comparator: ">=",
              Value: result.StartDate + "local"
            })

            this.Filters.push({
              Property: this.DateSearchProperty,
              Comparator: "<=",
              Value: result.EndDate + "local"
            })
          }
        })
    }
    else {
      var searchDates = val.split(',');

      this.Filters.push({
        Property: this.DateSearchProperty,
        Comparator: ">=",
        Value: searchDates[0]
      })

      this.Filters.push({
        Property: this.DateSearchProperty,
        Comparator: "<=",
        Value: searchDates[1]
      })
    }

    console.log("Push filters out");


  }

  public getLocalDates() {
    var today = new Date();
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var todayString = month[today.getMonth()] + " " + today.getDate();

    var startOfYesterday = new Date();
    startOfYesterday.setDate(startOfYesterday.getDate() - 1);
    startOfYesterday.setHours(0, 0, 0)
    var yesterdayString = month[startOfYesterday.getMonth()] + " " + startOfYesterday.getDate();

    var sevendaysago = new Date();
    sevendaysago.setDate(sevendaysago.getDate() - 6);
    sevendaysago.setHours(0, 0, 0)
    var last7 = month[sevendaysago.getMonth()] + " " + sevendaysago.getDate() + " - " + month[today.getMonth()] + " " + today.getDate();

    var startofthisweek = new Date();
    var todaydayofweek = today.getDay();
    startofthisweek.setDate(startofthisweek.getDate() - (todaydayofweek));
    startofthisweek.setHours(0, 0, 0)

    var endofthisweek = new Date();
    endofthisweek.setDate(startofthisweek.getDate() + 6);
    endofthisweek.setHours(23, 59, 59)
    var thisweekString = month[startofthisweek.getMonth()] + " " + startofthisweek.getDate() + " - " + month[today.getMonth()] + " " + endofthisweek.getDate();

    var startoflastweek = new Date();
    startoflastweek.setDate(startoflastweek.getDate() - (todaydayofweek + 7));
    startoflastweek.setHours(0, 0, 0)
    var endoflastweek = new Date();
    endoflastweek.setDate(endoflastweek.getDate() - (todaydayofweek + 1));
    endoflastweek.setHours(23, 59, 59)
    var lastweekString = month[startoflastweek.getMonth()] + " " + startoflastweek.getDate() + " - " + month[endoflastweek.getMonth()] + " " + endoflastweek.getDate();

    var monthString = month[today.getMonth()] + " " + today.getFullYear();

    var startofLastMonth = new Date();
    startofLastMonth.setMonth(startofLastMonth.getMonth() - 1);
    var firstDayofLastMonth = new Date(startofLastMonth.getFullYear(), startofLastMonth.getMonth(), 1);
    firstDayofLastMonth.setHours(0, 0, 0);
    var lastmonthString = month[firstDayofLastMonth.getMonth()] + " " + firstDayofLastMonth.getFullYear();

    this.DateSearchDates = {
      Today: { displayString: todayString },
      Yesterday: { displayString: yesterdayString },
      Last7Days: { displayString: last7 },
      ThisWeek: { displayString: thisweekString },
      LastWeek: { displayString: lastweekString },
      Month: { displayString: monthString },
      LastMonth: { displayString: lastmonthString }
    }
  }


}
