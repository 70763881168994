<app-loader [Parent]="this"></app-loader>

<form>
    <div class="dlv-bar mr-3 mb-5 p3">
        <ng-content></ng-content>
    </div>
</form>
<div class="columns loading-container">
    <div class="column" [ngClass]="ShowMap ? 'is-6' : 'is-12'">
        <div class="level mb-0 pb-0">
            <div class="level-left pl-2">
                <div *ngIf="Data.Results != null && ShowPaging == true" class="level-item">
                    <button class="button pagination-button is-small mr-3" (click)="PrevPage()"><i class="fa fa-chevron-left"></i></button>
                    Showing&nbsp;<span class="has-text-weight-bold">{{(Data.PageSize * (Data.PageNumber-1))+1}}</span>
                    &nbsp;to&nbsp;
                    <span class="has-text-weight-bold">{{Data.TotalRecords <= Data.PageSize ? Data.TotalRecords :
                            ((Data.PageSize * (Data.PageNumber-1))+Data.PageSize)}}</span>
                            &nbsp;of&nbsp;
                            <span class="has-text-weight-bold">{{Data.TotalRecords}}</span>
                    <button class="button pagination-button is-small ml-3" (click)="NextPage()"><i class="fa fa-chevron-right"></i></button>
                </div>
            </div>

            <div class="level-right">
                <div class="level-item">
                    <div class="select" *ngIf="ShowPaging == true">
                        <select [(ngModel)]="Filter.PageSize" (change)="PageSizeChanged()">
                            <option [value]="10">10</option>
                            <option [value]="15">15</option>
                            <option [value]="25">25</option>
                            <option [value]="50">50</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <table class="table is-striped">
            <thead>
                <tr>
                    <th *ngIf="AllowSelectBoxes">
                        Select
                    </th>
                    <th *ngFor="let h of Columns" [ngStyle]='{width: h.Width}'>{{h.Header}}</th>
                    <th class="buttoncolumn"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of Data.Results" [ngClass]="row == SelectedRow ? 'is-selected' : ''"
                    (click)="RowClicked(row)" (dblclick)="EditItem(row)">
                    <td *ngIf="AllowSelectBoxes">
                        <input type="checkbox" [disabled]="DisableIfSelected ? IsInSelectedItems(row) : false" [checked]="IsInSelectedItems(row)" (change)="RowSelectChanged($event, row)"/>
                    </td>
                    <td *ngFor="let col of Columns" [innerHTML]="sanitizer.bypassSecurityTrustHtml(col.Render(row))"
                        [ngClass]="getClass(row)">
                    </td>
                    <td class="buttoncolumn" [ngClass]="getClass(row)">
                        <button *ngIf="AllowEdit" type="button" class="button is-link is-small"
                            (click)="EditItem(row)"><i class="fa fa-chevron-right"></i></button>
                        <button *ngIf="AllowSelect" type="button" class="button is-primary" (click)="SelectItem(row)">
                            <span class="icon-text">
                                <span class="icon">
                                    <i class="fa fa-chevron-right"></i>
                                </span><span>Select</span>
                            </span>
                        </button>
                        <button *ngIf="AllowCustomAction" type="button" class="button is-primary is-small"
                            (click)="CustomActionOnAction(row)">
                            <span class="icon-text">
                                <span class="icon pt-2">
                                    <i class="fa fa-plus"></i>
                                </span>
                                <span>View</span>
                            </span>
                        </button>

                    </td>
                </tr>
            </tbody>
            <tfoot *ngIf="Data.Results != null && Data.Results.length == 0">
                <td colspan="999" class="has-text-centered">No Results!</td>
            </tfoot>
        </table>
        <div class="pr-3 mt-3" *ngIf="ShowPaging == true">
            <nav class="pagination  is-right" role="navigation" aria-label="pagination">
                <ul class="pagination-list">
                    <li>
                        <a class="pagination-previous" (click)="PrevPage()"><i class="fa fa-chevron-left"></i></a>
                    </li>
                    <li *ngFor="let p of Pages">
                        <a *ngFor="let i of p" class="pagination-link"
                            [ngClass]="{ 'is-current' : i == Filter.PageNumber}" (click)="GotoPage(i)">{{i}}</a>
                        <a class="pagination-ellipsis" *ngIf="p != Pages[Pages.length-1]">&hellip;</a>
                    </li>
                    <li>
                        <a class="pagination-next" (click)="NextPage()"><i class="fa fa-chevron-right"></i></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div *ngIf="ShowMap" class="column is-6" style="min-height: 80vh">
        <google-map [options]="MapOptions" height="100%" width="100%" [zoom]="15">
            <map-marker *ngFor="let p of MapPoints" [position]="p.marker" [options]="{ label: p.Name, icon: p.icon }">
            </map-marker>
            <map-polygon *ngFor="let p of MapPolygons" [paths]="p"
                [options]="{strokeColor : p == SelectedRow?.Polygon ? '#007237' : '#555', fillColor : p == SelectedRow?.Polygon ? '#007237' : '#555'}">
            </map-polygon>
        </google-map>
    </div>
</div>