import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/components/datalistviewcolumn';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {
  
  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "EmailName"),
    new Datalistviewcolumn("Id", "Id"),
    new Datalistviewcolumn("Type", "TemplateType")
  ];

  ngOnInit(): void {
  }


}
