<div *ngIf="roleInfo != null">
  <app-pageheader [Title]="roleInfo.Name">
    <button class="button is-link" routerLink="/roles/edit/{{roleInfo.Id}}" routerLinkActive="active">
        <span class="icon-text">
            <span>Edit</span>
            <span class="icon">
                <i class="fa fa-chevron-right"></i>
            </span>
        </span>
    </button>
  </app-pageheader>
  <div class="columns">
    <div class="column is-12">
      <article class="panel">
        <app-tabs>
          <app-tab Title="Role Information">
            <div class="columns" style="padding:20px;">
              <div class="column is-9">
                <app-field Label="Role Id">
                  <div class="control">
                    <p class="input" disabled>{{roleInfo.Id}}</p>
                  </div>
                </app-field>
                <app-field Label="Name">
                  <div class="control">
                    <p class="input" disabled>{{roleInfo.Name}}</p>
                  </div>
                </app-field>
                <app-field Label="Permissions">
                  <div class="field is-grouped is-grouped-multiline">
                    <div class="control" *ngFor="let p of roleInfo.RolePermissions; let i = index;">
                      <div class="tags has-addons">
                        <p class="tag">{{p.Permission}} ({{p.PermissionLevelString}})</p>
                      </div>
                    </div>
                  </div>
                </app-field>
                </div>
              </div>
          </app-tab>
          <app-tab Title="Role Users">
            <app-datalistview *ngIf="roleInfo != null && roleInfo.Id != null" [Columns]="Columns" [PageSize]="10" 
              [ShowMap]="false" [ApiUrl]="'/master/roles/' + roleInfo?.Id + '/users/searches'"
              [AllowEdit]="true" EditUrl="/users/:UserId/organizationuser/edit/:Id">
              <app-filter-set>
                <div app-search-filter></div>
                <div app-search-button></div>
                
                <a class="button is-link" style="float:right; margin-left:20px" (click)="showAddRoleUserModal()">
                  <span class="icon-text">
                    <span>Add</span>
                    <span class="icon">
                      <i class="fa fa-plus"></i>
                    </span>
                  </span>
                </a>
              </app-filter-set>
            </app-datalistview>
        </app-tab>
      </app-tabs>
    </article>
    </div>
  </div>
</div>
