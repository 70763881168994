import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/components/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editorganization',
  templateUrl: './editorganization.component.html',
  styleUrls: ['./editorganization.component.scss']
})
export class EditorganizationComponent extends ModelEditor implements OnInit {

  public features: any[] = [];
  public TimeZones: any[] = [];
  public Currencies: any[] = [];
  public SelectedCurrency: any = null;
  public boolExpandAll: boolean = false;
  public expandSelected: boolean = false;
  public SelectedFeatureName = "";
  public SelectedFeatureDefinition = "";
  public SelectedFeatureRoutes: any[] = [];
  public SelectedFeaturePrerequisites: any[] = [];

  public LogLevels: any[] = [
    { Value: 0, Name: "Trace" },
    { Value: 1, Name: "Debug" },
    { Value: 2, Name: "Info" },
    { Value: 3, Name: "Warn" },
    { Value: 4, Name: "Error" },
    { Value: 5, Name: "Fatal" },
  ];

  constructor(private apiService: ApiServiceBase, private injector: Injector) {
    super("master/organizations", injector);

    this.apiService.Get<any>("features/all").then(result => {
      this.features = result;
    });

    this.apiService.Get<any>("master/timezones/all").then(result => {
      this.TimeZones = result;
    })

    this.apiService.Get<any>("master/currencies/all").then(result => {
      this.Currencies = result;
      this.SelectedCurrency = this.Currencies.filter(x => x.Code == this.Model.CurrencyCode)[0];
    })
  };

  public TimeZoneChanged(evt: any){
    this.Model.TimeZoneId = evt.target.value;
  }

  public CurrencyChanged(evt: any){
    this.SelectedCurrency = this.Currencies.filter(x => x.Code == evt.target.value)[0];
    this.Form.get("CurrencyCode")?.setValue(this.SelectedCurrency.Code);
    this.Form.get("CurrencySymbol")?.setValue(this.SelectedCurrency.Symbol);
  }


  public IsSelected(item: any): boolean {
    var installedFeatures = this.Form.get("Features")?.value;
    if (installedFeatures == null) {
      return false;
    }
    var t = installedFeatures.filter((x: any) => x.Name == item.ClassName);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public ClickItem(item: any) {
    this.SelectedFeatureDefinition = item.Description;
    this.SelectedFeatureName = item.Name;
    this.SelectedFeatureRoutes = item.AdminMenuItems;
    this.SelectedFeaturePrerequisites = item.PrerequisiteFeatures;
  }

  public UpdateSelectedItem(item: any) {
    var x = this.IsSelected(item);
    if (x) {
      //item exists in list, so remove.
      item.IsSelected = false;
      var g = this.Form.get("Features")?.value;
      var index = g.findIndex((t: any) => t.Name == item.ClassName);
      this.FormArray(this.Form, "Features").removeAt(index);

      //remove parents
      if(item.Parent != null)
      {
        var item = item.Parent;
        var isParentSelected = this.IsSelected(item);
        if (isParentSelected) 
        {
          item.HasChildItemsSelected = item.Children.filter((x: any) => x.IsSelected == true).length > 0;
          item.IsSelected = item.HasChildItemsSelected;
          //item exists in list, so remove.
          if(!item.IsSelected){
            g = this.Form.get("Features")?.value;
            index = g.findIndex((x: any) => x.Name == item.ClassName);
            this.FormArray(this.Form, "Features").removeAt(index);
          }

          //remove parents
          if(item.Parent != null)
          {
            var item = item.Parent;
            isParentSelected = this.IsSelected(item);

              if (isParentSelected) {
                  item.HasChildItemsSelected = item.Children.filter((x: any) => x.IsSelected == true).length > 0;
                  item.IsSelected = item.HasChildItemsSelected;
                  //item exists in list, so remove.

                  if(!item.IsSelected){
                    g = this.Form.get("Features")?.value;
                    index = g.findIndex((x: any) => x.Name == item.ClassName);
                    this.FormArray(this.Form, "Features").removeAt(index);
                  }
                }
          }
        }
      }
    }
    else {

      //add prerequisites
      if(item.PrerequisiteFeatures != null && item.PrerequisiteFeatures.length > 0){
        item.PrerequisiteFeatures.forEach((prereq : any) => {
          //add and select prereq
          var isPrereqSelected = this.IsSelected(prereq);

          if(!isPrereqSelected)
          {
            var newf = {
              AdminMenuItems: null,
              FeatureName: prereq.Name,
              GrantableAdminPermissionLevels: null,
              GrantableEndUserPermissionLevels: null,
              Name: prereq.ClassName,
              Type: null
            }

            prereq.IsSelected = true;
            this.AddToFormArray(this.FormArray(this.Form, "Features"), newf, "Features");
            };
          });
        };


      //item does not exist in list, so add.
      var newf = {
        AdminMenuItems: null,
        FeatureName: item.Name,
        GrantableAdminPermissionLevels: null,
        GrantableEndUserPermissionLevels: null,
        Name: item.ClassName,
        Type: null
      }
      item.IsSelected = true;
      this.AddToFormArray(this.FormArray(this.Form, "Features"), newf, "Features");

      //add parents to list
        if(item.Parent != null)
        {
            var item = item.Parent;
            isParentSelected = this.IsSelected(item);
            if(!isParentSelected){
                newf = {
                  AdminMenuItems: null,
                  FeatureName: item.Name,
                  GrantableAdminPermissionLevels: null,
                  GrantableEndUserPermissionLevels: null,
                  Name: item.ClassName,
                  Type: null
                }
                item.IsSelected = true;
                this.AddToFormArray(this.FormArray(this.Form, "Features"), newf, "Features");
            }

            //add parents to list
            if(item.Parent != null)
            {
              item = item.Parent;
              isParentSelected = this.IsSelected(item);
              
              if(!isParentSelected){
                newf = {
                  AdminMenuItems: null,
                  FeatureName: item.Name,
                  GrantableAdminPermissionLevels: null,
                  GrantableEndUserPermissionLevels: null,
                  Name: item.ClassName,
                  Type: null
                }
              item.IsSelected = true;
              this.AddToFormArray(this.FormArray(this.Form, "Features"), newf, "Features");
            }
            }
          }
        }    
    this.Form.markAsDirty();        
    this.ProcessFeatures();
  };

  public override DefaultModel(): any {
    return {
      Name: "",
      Features: []
    };
  }

  public ProcessFeatures(){
    this.features.forEach((element: any) => {
        this.ProcessLevel(element);
    });
  }

  public ProcessLevel(element: any) {
    element.Parent = null;
    element.IsSelected = this.IsSelected(element);
    element.HasCheckbox = true;
    element.Expanded = this.boolExpandAll || (this.expandSelected && element.HasChildItemsSelected);
    element.Children?.forEach((child: any) => {
      this.ProcessLevel(child);
    });
    element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y : any) => y.IsSelected).length > 0).length > 0;
    return element;
  }

  public ExpandAll(){
    this.boolExpandAll = true;
    this.ProcessFeatures();
  }

  public CollapseAll(){
    this.boolExpandAll = false;
    this.expandSelected = false;
    this.ProcessFeatures();
  }

  public ExpandSelected(){
    this.boolExpandAll = false;
    this.expandSelected = true;
    this.ProcessFeatures();
  }

  public override AfterModelLoaded(): void {
    this.ProcessFeatures();
    this.SelectedCurrency = this.Currencies.filter(x => x.Code == this.Model.CurrencyCode)[0];
  }

  public override BeforeSave(): void {

  }

  public override Validators(): any {
  }
}
