<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
  <app-pageheader [Title]="'Edit Email: ' + this.Form.get('EmailName')?.value" >
    <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
  </app-pageheader>
<article class="panel">
<app-tabs>
  <app-tab Title="Email Details">
    <div class="columns">
      <div class="column is-6">
        <app-field Label="Id">
            <div class="control">
                <input class="input" [value]="this.Form.get('Id')?.value" disabled/>
            </div>
        </app-field>
        <app-field Label="Email Name">
          <div class="control">
            <input formControlName="EmailName" class="input" type="text" name="EmailName" required />
          </div>
        </app-field>
        <app-field Label="Template Type">
          <div class="control">
            <select class="input minimal" (change)="TypeChanged($event)" formControlName="TemplateType" required>
              <option *ngFor="let i of EmailTypes" [value]="i.CommunicationType" [selected]="i.CommunicationType == this.Form.get('TemplateType')?.value ? true : null">{{i.DisplayName}}</option>
          </select>
          </div>
        </app-field>
        <app-field Label="Subject" *ngIf="this.Form.get('TemplateType')?.value != 'Layout'">
            <div class="control">
                <input formControlName="Subject" class="input" [value]="this.Form.get('Subject')?.value"/>
              </div>
            </app-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <app-field Label="Body">
              <div class="control">
                <app-codeeditor  Mode="html" *ngIf="this.Body != null" [(Model)]="this.Body" (ModelChange)="BodyChanged($event)" #editor>
                </app-codeeditor>
              </div>
            </app-field>
            <app-field Label="Mobile Body" *ngIf="this.Model.MobileNotificationEventName != null">
              <div class="control">
                <app-codeeditor  Mode="html" *ngIf="this.MobileNotificationBody != null" [(Model)]="this.MobileNotificationBody" (ModelChange)="MobileBodyChanged($event)" #editor>
                </app-codeeditor>
              </div>
            </app-field>
        </div>
    </div>
</app-tab>
</app-tabs>
</article>