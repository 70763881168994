import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Busyable } from 'src/app/components/busyable';
import { LoginServiceBase } from '../login.service.base';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styleUrls: ['./loginform.component.scss']
})
export class LoginformComponent extends Busyable implements OnInit {

  @Input()
  public RedirectUrl: string | null = null;

  public user = { username: '', password: '' };
  public ErrorMessage: string = "";

  constructor(private loginService: LoginServiceBase, private router: Router) {
    super();
  }

  ngOnInit(): void {
  }

  public Login() {
    this.loginService.DoLogin(this.user.username, this.user.password).then(result => {
      if (result.Success) {
        if (this.RedirectUrl != null) {
          this.router.navigate([this.RedirectUrl]);
        }
      }
      else {
        this.ErrorMessage = result.Error.Message;
      }
    });
  }

  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      this.Login();
    }
  }
}
