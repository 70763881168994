import { Component, Input, OnInit } from '@angular/core';
import { Datalistrequestfilter } from '../../datalistrequestfilter';
import { IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-admin-filter]',
  templateUrl: './admin-filter.component.html',
  styleUrls: ['./admin-filter.component.scss'],
  host: { 'class': 'control select' }
})
export class AdminFilterComponent implements IFilterFilters, OnInit {
  @Input()
  public SelectedIsAdmin: any = false;
  constructor() { }
  ngOnInit(): void {
    this.Filters.push({
      Property: "IsAdmin",
      Comparator: "==",
      Value: this.SelectedIsAdmin
    });
  }
  public Filters: Datalistrequestfilter[] = [];

  public addIsAdminFilter(event: any) {
    var val = event.target.value;

    if (val != "") {
      this.Filters = [];
      this.Filters.push({
        Property: "IsAdmin",
        Comparator: "==",
        Value: val
      });
    }
  }
}
