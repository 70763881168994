import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Busyable } from '../../busyable';

@Component({
  selector: 'app-form-save-button',
  templateUrl: './form-save-button.component.html',
  styleUrls: ['./form-save-button.component.scss']
})
export class FormSaveButtonComponent implements OnInit {


  @Input()
  public Parent : Busyable | null = null;
  @Input()
  public ButtonType : string = "button";
  @Input()
  public Form : any | null = null;

  public Animate : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.Parent?.BusyStarted.subscribe(e => {
      this.Animate = true;
    })
    this.Parent?.BusyEnded.subscribe(e => {
      this.Animate = false;
    })
  }
  public Clicked(evt : any){
    //check if we are already busy or if the form isn't dirty
    if(this.Animate == true || !this.IsFormDirty()){
      evt.stopPropagation();
    }
  }
  public IsFormDirty() : boolean {
    if(this.Form == null) return false;

    if(this.Form.status){
      //reactive forms
      //return this.RecursiveFindDirty(this.Form);
      return this.Form.valid && this.Form.dirty;
    }

    return this.Form.classList.contains('ng-dirty');
  }
  private RecursiveFindDirty(group : FormGroup) : boolean{
    if(group.dirty && group.valid)
      return true;
    for(let prop in group.controls){
      if(group.get(prop) instanceof FormArray){
        //property is a formarray
        let arr = group.get(prop) as FormArray;
        for(let c of arr.controls){
          if(c.dirty && c.valid)
            return true;
          if(this.RecursiveFindDirty(c as FormGroup)) 
            return true;
        }
      }
    }
    return false;
  }
}
