import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent> = new QueryList();
  @Output() SelectedIndexChanged = new EventEmitter<number>();
  @Output() TabInsertRequested = new EventEmitter<number>();
  @Input()
  public AllowInsertRequest : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  
  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab)=>tab.Active);
    
    // if there is no active tab set, activate the first
    if(activeTabs.length === 0 && this.tabs.length > 0) {
      this.selectTab(this.tabs.first);
    }
  }
  
  selectTab(tab: TabComponent){
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => tab.Active = false);
    // activate the tab the user has clicked on.
    tab.Active = true;
    
    let newTabIndex : number = 0;
    let arrayTabs = this.tabs.toArray();
    for(let i=0; i < arrayTabs.length; i++){
      if(arrayTabs[i] == tab){
        newTabIndex = i;
        break;
      }
    }
    this.SelectedIndexChanged.emit(newTabIndex);
  }

  public Insert(index : number){
    this.TabInsertRequested.emit(index);
  }
}
