import { formatCurrency } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: '[app-currency-input]',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss']
})
export class CurrencyInputComponent implements OnInit, ControlValueAccessor {

  public InternalValue : number | null = 0;
  public DisplayValue : string = "";
  private DecimalSeparator : string = ".";
  @Input()
  public Disabled : boolean = false;


  constructor(private organizationsService : OrganizationsService,  @Self() public ngControl: NgControl) { 
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
    //find the char used by this locale as a decimal separator for later parsing
    let separatorString = 1.1;
    this.DecimalSeparator = separatorString.toLocaleString().substring(1, 2);
  }

  ngOnInit(): void {
    //this.UpdateDisplayedValue();
  }
  public writeValue(val : number | null): void {
    this.InternalValue = val;
    this.UpdateDisplayedValue();
  }
  public onChange = (val : number | null) => {};
  public registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  public onTouched = () => {};
  public registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  public InputEntered(){
    if(this.InternalValue != null){
      this.DisplayValue = this.InternalValue.toString();
    }
  }

  public InputBlurred(){
   this.UpdatedByUser();
   this.onChange(this.InternalValue);

  }

  public UpdatedByUser(){
    if(this.DisplayValue != ""){
      //extract number from input
      let regex = new RegExp("([\\d|" + this.DecimalSeparator + "]+)");
      let nums = this.DisplayValue.match(regex);
      if(nums != null && nums.length > 0){
        let val = parseFloat(nums[0].replace(",", "."));
        this.InternalValue = val;
        this.UpdateDisplayedValue();
        return;
      }
    }
    this.InternalValue = null;
   this.DisplayValue = "";
  }
  public UpdateDisplayedValue(){
    if(this.InternalValue != null)
      this.DisplayValue = formatCurrency(this.InternalValue, this.organizationsService.GetLocale(), this.organizationsService.GetCurrencySymbol(), '');
    else
      this.DisplayValue = "";
  }


}
