import { animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Busyable } from '../../busyable';

@Component({
  selector: 'actionbutton',
  templateUrl: './actionbutton.component.html',
  styleUrls: ['./actionbutton.component.scss']
})
export class ActionbuttonComponent implements OnInit {

  @Input()
  public Parent : Busyable | null = null;
  @Input()
  public ButtonType : string = "button";
  @Input()
  public Icon : string = "check";
  @Input()
  public Text : string | undefined;
  @Input()
  public Enabled : any = true;

  public Animate : boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.Parent?.BusyStarted.subscribe(e => {
      this.Animate = true;
    })
    this.Parent?.BusyEnded.subscribe(e => {
      this.Animate = false;
    })
  }
}
