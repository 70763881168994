import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-delete-check',
  templateUrl: './delete-check.component.html',
  styleUrls: ['./delete-check.component.scss']
})


export class DeleteCheckComponent extends SimpleModalComponent<any, any> implements OnInit {

  deleteCheckResult: any | null = null;

  public modelId!: string;
  public url: string = "";
  public name!: string;
  public deleteCheck: boolean = false;
  public deleteNavLink: string | null = null;
  public api!: string;
  public typeName!: string | null;

  constructor(private router: Router, private apiService: ApiService) {
    super();
  }

  ngOnInit(): void {
    if(this.deleteCheck) {
      this.apiService.Get('/' + this.api + '/deletecheck/' + this.typeName + '/' + this.modelId).then((result: any) => {
        this.deleteCheckResult = result;
      })
    }
    else {
      this.deleteCheckResult = { "CanDelete" : true };
    }

  }

  cancel() {
    this.result = false;
    this.close();
  }

  delete() {
    if(this.deleteCheckResult['CanDelete']) {
      this.apiService.Delete(this.url + this.modelId, {}).then((result: any) => {
        this.router.navigate([this.deleteNavLink]);
      });
    }
    this.close();
  }
}
