<app-pageheader Title="Roles">
  <app-datalist-add-button AddRoute="roles/edit/new"></app-datalist-add-button>
</app-pageheader>

  <app-datalistview [Columns]="Columns" [ShowMap]="false" ApiUrl="master/roles/search"
  [AllowEdit]="true" EditUrl="roles/:id">
  <app-filter-set>
    <div app-search-filter></div>
    <div app-search-button></div>
  </app-filter-set>
</app-datalistview>