<div class="treenode">
    <div>
        <span *ngIf="HasCheckbox">
            <input *ngIf="Checked" type="checkbox" style="accent-color: green;" (click)="HandleSelectedClick($event)" checked [disabled]="NodeDisabled">
            <input *ngIf="!Checked" type="checkbox" style="accent-color: green;" (click)="HandleSelectedClick($event)" [disabled]="NodeDisabled">
        </span>
        <span class="node-text" [ngClass]="{ 'is-active' : Selected}" [ngStyle]="{'color': (HasChildItemsSelected || Checked) ? '#2E8637' : 'black', 'font-weight': (HasChildItemsSelected || Checked) ? 'bold' : 'normal'}"  (click)="HandleClick($event)" (dblclick)="HandleDoubleClick($event)">
            <img class="node-image" *ngIf="Image != null" [src]="Image" />
            {{Text}}
        </span>
        <span *ngIf="SelectItems != null && SelectItems.length > 1" class="has-text-grey-light">
            <select [(ngModel)]="SelectItemValue" (change)="HandleSelectedChange($event)" [disabled]="NodeDisabled">
                <option value="0" disabled selected>No Access</option>
                <option *ngFor="let s of SelectItems" [value]="s.Level" [selected]="">{{s.Permission}}</option>
            </select>
        </span>
        <span class="fa fa-chevron-right" *ngIf="CanExpand && !Expanded" (click)="Toggle()"></span>
        <span class="fa fa-chevron-down" *ngIf="CanExpand && Expanded" (click)="Toggle()"></span>
        <span *ngIf="CanDelete" (click)="HandleDelete($event)" class="has-text-grey-light"><i class="fa fa-trash"></i> </span>
        <span class="has-text-grey-light">{{TrailingInfo}}</span>
    </div>
    <div class="node-content" *ngIf="Expanded">
        <ng-content></ng-content>
    </div>
</div>