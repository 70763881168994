import { Component, OnInit, SimpleChanges } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

export interface AddRoleUserModal {
  RoleId: string;
}

@Component({
  selector: 'app-addroleuser',
  templateUrl: './addroleuser.component.html',
  styleUrls: ['./addroleuser.component.scss']
})

export class AddRoleUserComponent extends SimpleModalComponent<AddRoleUserModal, any> implements OnInit {
  RoleId!: string;

  public UserId: string | null = null;
  public User: any = null;

  public InitialString: string = "";

  public SearchResults: any[] = [];
  public SearchTerm: string = "Email";
  public IsLoading: boolean = false;

  constructor(private apiService: ApiServiceBase ) {
    super();
  }

  ngOnInit(): void {
  }

  public addRoleUser() {
    this.apiService.Put<any>("master/organizationusers/" + this.User.Id + '/role/' + this.RoleId , null);
    this.close();
  }

  exit() {
    this.close();
  }

  public ItemSelected(item: any) {
    if (item && item != null && item.Id) {
      this.User = { Id: item.Id, FirstName: item.FirstName, LastName: item.LastName, Email: item.Email };
      this.UserId = item.Id;
    }
  }

  public SearchChanged(val: string) {
    console.log("User Selector search event: '" + val + "'");
    if (val == null || val == "") return;
    this.User = null;
    this.IsLoading = true;
    this.apiService.Post<any>("master/organizationusers/searches", { PageNumber: 1, PageSize: 15, SearchTerm: val }).then(result => {
      this.SearchResults = result.Results;
      this.IsLoading = false;
    });
  }

  public ClearUser() {
    this.UserId = null;
  }

  public KeyPress(evt: any) {
    if (evt.keyCode == 13) {
      evt.stopPropagation();
      console.log("intercepted enter key on user selector");
    }
  }
  public InputKeyPress(evt: any) {

  }

}
