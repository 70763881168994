import { getLocaleNumberFormat } from '@angular/common';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoginServiceBase } from '../auth/login.service.base';
import { LoginService } from '../auth/login.service';
import { Geo } from '../util/geo';
import { ApiServiceBase } from './api.service.base';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  @Output()
  public AvailableOrganizationsChanged = new EventEmitter<any>();

  private Permissions = [{ Permission: "", PermissionLevel: 0 }];
  private Organizations: any[] = [];
  private CurrentOrganization: any = null;

  constructor(private apiService: ApiServiceBase, private loginService: LoginServiceBase) {
    loginService.LoginStatusChanged.subscribe(loggedIn => {
      if (loggedIn == true) {
      }
      else {
        this.Organizations = [];
      }
    });
    if (loginService.IsLoggedIn()) {
    }
  }

  private GetOrganization(id?: string | null) {
    if (id == null) {
      if (this.CurrentOrganization != null && this.CurrentOrganization.Id == this.loginService.CurrentOrganizationId()) {
        return this.CurrentOrganization;
      }
      let orgId = this.loginService.CurrentOrganizationId();
      this.CurrentOrganization = this.Organizations.filter((x: any) => x.Id == orgId)[0];
      return this.CurrentOrganization;
    }
    else {
      let org = this.Organizations.filter(x => x.Id == id);
      if (org.length > 0)
        return org[0];
      return {};
    }
  }

  public async GetAvailableOrganizations() {
    console.warn("This call shouldn't be needed.");
    if(this.Organizations = [])
      await this.apiService.Get<any>("master/organizations").then(result => {
        this.Organizations = result;
        this.AvailableOrganizationsChanged.emit();
      });
    return this.Organizations;
  }
  public GetOrganizationName(id?: string | null) {
    return this.GetOrganization(id).Name;
  }

  public GetOrganizationLocation(id?: string | null): number[] {
    let org = this.GetOrganization(id);
    if (org == null || org.LocationPoints == null) return [0, 0];
    if (org.LocationPoints != null)
      return org.LocationPoints;
    return [0, 0];
  }
  public GetCurrencySymbol(): string {
    return "$";
  }
  public GetCurrencyCode(): string {
    return "USD";
  }
  public GetLocale(): string {
    return "en-US";
  }
  public GetOrganizationDefaultPolygon(id?: string | null): number[][] {
    let org = this.GetOrganization(id);

    if (org.LocationPoints != null) {
      return Geo.NewPolygonAtCenter(org.LocationPoints, 0.001);
    }
    return [[0, 0]];
  }
  public GetTimeZoneName(id?: string | null) {
    return this.GetOrganization(id).TimeZoneDisplayName;
  }

}
