import { EventEmitter } from "@angular/core";

export abstract class LoginServiceBase {
  public LoginStatusChanged = new EventEmitter<any>();
  public OrganizationChanged = new EventEmitter<any>();

  public abstract IsLoggedIn(): boolean;
  public abstract LoggedInFirstname(): string;
  public abstract CurrentToken(): string;
  public abstract CurrentOrganizationId(): string;
  public abstract UserId(): string;
  public abstract DoLogin(username: string, password: string): Promise<any>;
  public abstract DoLogout(): void;
  public abstract DoOrganizationSwitch(id: string): void;
  public abstract NotifyAuthExpired(): void;
  public abstract DebugBreakAuthToken(): void;
}
