import { Component, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Color } from 'src/app/util/color';

@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss']
})
export class ColorpickerComponent implements OnInit, ControlValueAccessor {

  public ColorValue : string = "ffffff";


  constructor(@Self() public ngControl: NgControl) { 
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    //this.UpdateDisplayedValue();
  }
  public writeValue(val : string): void {
    this.ColorValue = val;
  }
  public onChange = (val : string | null) => {};
  public registerOnChange(fn: any): void {
      this.onChange = fn;
  }
  public onTouched = () => {};
  public registerOnTouched(fn: any): void {
      this.onTouched = fn;
  }

  public ColorChanged(evt : string){
    this.ColorValue = evt.substring(1);
    this.onChange(this.ColorValue);
  }
  public ClassForColor(){
    return Color.GetVisibleTextClassFor(this.ColorValue);
  }
}
