import { Component, OnInit } from '@angular/core';
import { BooleanTextViewColumn, BooleanViewColumn, Datalistviewcolumn, DatalistviewPercentColumn } from 'src/app/components/datalistviewcolumn';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("FirstName", "FirstName"),
    new Datalistviewcolumn("LastName", "LastName"),
    new Datalistviewcolumn("Email", "Email"),
    new BooleanTextViewColumn("Login Type", "IsExternal", ['Facebook', 'Email']),
  ];

  ngOnInit(): void {
  }
}