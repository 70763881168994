import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-treenoderecursive',
  templateUrl: './treenoderecursive.component.html',
  styleUrls: ['./treenoderecursive.component.scss']
})
export class TreenoderecursiveComponent implements OnInit {

  @Input() recursiveList: any;
  @Input() NodeDisabled: boolean = false;
  @Output() UpdateItem = new EventEmitter<any>();
  @Output() ItemClicked = new EventEmitter<any>();
  @Output() UpdateItemSelectedValue = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public UpdateSelectedItem(item : any){
    this.UpdateItem.emit(item);
  }

  public ClickItem(item : any){
    this.ItemClicked.emit(item);
  }

  public UpdateSelectedItemValue(evt: any, item : any){
    item.SelectItemValue = evt.target.value;
    if(!item.IsSelected) this.UpdateSelectedItem(item);
    this.UpdateItemSelectedValue.emit(item);
  }

  public SendSelectedItemValue(item: any)
  {
    this.UpdateItemSelectedValue.emit(item);
  }
}