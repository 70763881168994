import { Component, Input, OnInit } from '@angular/core';
import { IFilter, ISearchTermFilter } from '../ifilter';

@Component({
  selector: '[app-search-filter]',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  host: {'class': 'control has-icons-right'}
})
export class SearchFilterComponent implements ISearchTermFilter {

  @Input()
  public AllowSearchClear: boolean = true;

  constructor() { }

  public SearchTerm: string = '';

  public async ClearSearch() {
    this.SearchTerm = '';
  }

}
