<div class="tabs is-boxed">
    <ul>
        <li *ngFor="let tab of tabs; let i = index" (click)="selectTab(tab)" [class.is-active]="tab.Active" class="tab">
            <a>
                <span>{{tab.Title}}</span>
                <span *ngIf="AllowInsertRequest && i != tabs.length-1" class="fa fa-plus inserticon" (click)="Insert(i)"></span>
            </a>
        </li>
    </ul>
</div>
<div class="tab-body">
    <ng-content></ng-content>
</div>
