<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
    <header class="modal-card-head">
        <p class="modal-card-title">Add Role User</p>
    </header>
    <section class="modal-card-body"style="min-width: 800px; min-height: 350px;">
        <app-field Label="User">
            <div class="control">
                <div *ngIf="UserId != null" class="field has-addons">
                    <div class="control is-expanded">
                      <input class="input" [value]="User.LastName + ', ' + User.FirstName" disabled>
                      
                    </div>
                      <button type="button" class="button is-light" (click)="ClearUser()">
                        <i class="fa fa-trash"></i>
                      </button>
                    
                  </div>
                  <div *ngIf="UserId == null" class="ng-autocomplete position" style="width:100%;" (keypress)="KeyPress($event)">
                    <ng-autocomplete 
                      [data]="SearchResults"
                      [searchKeyword]="SearchTerm"
                      placeholder="Select user"
                      (selected)='ItemSelected($event)'
                      (inputChanged)='SearchChanged($event)'
                      [itemTemplate]="itemTemplate"
                      [notFoundTemplate]="notFoundTemplate"
                      [debounceTime]="300"
                      [isLoading]="IsLoading"
                      (inputCleared)="ClearUser()"
                      >
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.FirstName + ' ' + item.LastName + ' (' + item.Email + ')'"></a>
                    </ng-template>
                    
                    <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
            </div>
        </app-field>
    </section>
    <footer class="modal-card-foot">
        <button class="button is-danger" (click)="close()">Cancel</button>
        <button class="button is-primary" (click)="addRoleUser()">Add User to Role</button>
    </footer>
    </div>
</div>