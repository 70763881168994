import { Component, OnInit } from '@angular/core';
import { ApiService } from '../Services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public SystemAdminOverview = {UserCount: 0, OrganizationCount: 0, RoleCount: 0, EmailTemplateCount: 0};
  constructor(public apiService: ApiService) { }

  ngOnInit(): void {
    this.apiService.Get<any>("master/overview").then((result : any) =>{
      this.SystemAdminOverview = result;
    });
  }

}
