import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToastrService } from 'ngx-toastr';
import { ModelEditor } from 'src/app/components/modeleditor';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { OrganizationsService } from 'src/app/Services/organizations.service';

@Component({
  selector: 'app-editemail',
  templateUrl: './editemail.component.html',
  styleUrls: ['./editemail.component.scss']
})
export class EditEmailComponent extends ModelEditor implements OnInit {

  public Body = "";
  public MobileNotificationBody = "";
  public EmailTypes: any[] = [];

  constructor(private apiService: ApiServiceBase, private injector : Injector) {
    super("master/communications/emailtemplates", injector);

    this.apiService.Get<any>("master/communications/emailtypes").then(result => {
      this.EmailTypes = result;
    });
  };

  public override DefaultModel(): any {
    return {
      EmailName: "",
      Subject: "",
      Body: "",
      TemplateType: "",
      MobileNotificationBody: "",
    };
  }

  public override AfterModelLoaded(): void {
    this.Body = this.Form.get('Body')?.value;
    this.MobileNotificationBody = this.Form.get('MobileNotificationBody')?.value;
  }

  public override BeforeSave(): void {

  }

  public override Validators(): any {
  }

  public BodyChanged(event: any) {
    // Update form and mark as such
    this.Form.get('Body')?.setValue(event);
    this.Form.get('Body')?.markAsDirty();
  }

  public MobileBodyChanged(event: any) {
    // Update form and mark as such
    this.Form.get('MobileNotificationBody')?.setValue(event);
    this.Form.get('MobileNotificationBody')?.markAsDirty();
  }

  public TypeChanged(event: any) {
    this.Form.get('TemplateType')?.setValue(event.target.value);
  }
}
