import { Component, Injector, OnInit } from '@angular/core';
import { ModalService } from 'src/app/Services/modal.service';
import { SimpleModalService } from 'ngx-simple-modal';
import { ModelEditor } from 'src/app/components/modeleditor';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { LoginService } from 'src/app/auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { FeaturesService } from 'src/app/Services/features.service';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { UsermagicmoneyComponent } from '../edituser/usermagicmoney/usermagicmoney.component';
import { PermissionsService } from 'src/app/Services/permissions.service';

@Component({
  selector: 'app-editorganizationuser',
  templateUrl: './editorganizationuser.component.html',
  styleUrls: ['./editorganizationuser.component.scss']
})
export class EditorganizationuserComponent extends ModelEditor implements OnInit {
  public features: any[] = [];
  public InitialUser: any = {};
  public allfeatures: any[] = [];
  public allroles : any[] = [];
  public Organizations: any[] | null = null;
  public boolExpandAll: boolean = false;
  public expandSelected: boolean = false;
  public grantedroles: any[] = [];
  public grantedRolePermissions: any[] = [];
  public endUserfeatures: any[] = [];
  public SelectedFeatureName = "";
  public SelectedFeatureDefinition = "";
  public SelectedFeatureRoutes: any[] = [];
  public SelectedFeaturePrerequisites: any[] = [];
  public NewOrgUser: boolean = true
  constructor(private modalService: SimpleModalService, private apiService: ApiServiceBase, private route: ActivatedRoute, public featuresService: FeaturesService, private permissionService: PermissionsService, private injector: Injector) {
    super("master/organizationusers", injector);
  }
   
  public override DefaultModel(): any {
    return {
      UserId: null,
      OrganizationId: null,
      OrganizationName: "",
      Features: [],
      Roles: [],
      AdminPermissions: [],
      EndUserPermissions:[],
      AccountBalance: 0,
      MagicMoneyAccountBalance: 0,
      IsAdmin : false
    };
  }

  public ClickItem(item: any) {
    this.SelectedFeatureDefinition = item.Description;
    this.SelectedFeatureName = item.Name;
    this.SelectedFeatureRoutes = item.AdminMenuItems;
    this.SelectedFeaturePrerequisites = item.PrerequisiteFeatures;
  }

  public ProcessExplicitFeatures() {
    this.endUserfeatures = [];
    if(this.features != null && this.features.length > 0){
      this.features.forEach((element: any) => {
      
        if (element.GrantableEndUserPermissionLevels) {
          this.endUserfeatures.push({ "ClassName": element.ClassName, "Name": element.Name, "IsSelected": this.IsEndUserPermissionSelected(element), "SelectItems": element.GrantableEndUserPermissionLevels, "SelectItemValue": this.GrantedEndUserPermissionLevel(element), "HasCheckbox": true })
        }
        element.Parent = null;
        element.NodeDisabled = !this.permissionService.CheckAdminPermission(element.ClassName, 100);
        element.Expanded = this.boolExpandAll || (this.expandSelected && element.HasChildItemsSelected);;
        element.IsSelected = this.IsAdminPermissionSelected(element);
        element.SelectItems = element.GrantableAdminPermissionLevels;
        element.SelectItemValue = this.GrantedAdminPermissionLevel(element);
        element.HasCheckbox = true;

        element.Children?.forEach((child: any) => {
          if (child.GrantableEndUserPermissionLevels) {
            this.endUserfeatures.push({ "ClassName": child.ClassName, "Name": child.Name, "IsSelected": this.IsEndUserPermissionSelected(child), "SelectItems": child.GrantableEndUserPermissionLevels, "SelectItemValue": this.GrantedEndUserPermissionLevel(child), "HasCheckbox": true })
          }

          child.Parent = element;
          child.NodeDisabled = !this.permissionService.CheckAdminPermission(element.ClassName, 100);
          child.Expanded = this.boolExpandAll || (this.expandSelected && child.HasChildItemsSelected);
          child.IsSelected = this.IsAdminPermissionSelected(child);
          child.SelectItems = child.GrantableAdminPermissionLevels;
          child.SelectItemValue = this.GrantedAdminPermissionLevel(child);
          child.HasCheckbox = true;
          child.Children?.forEach((c: any) => {
            if (c.GrantableEndUserPermissionLevels) {
              this.endUserfeatures.push({ "ClassName": c.ClassName, "Name": c.Name, "IsSelected": this.IsEndUserPermissionSelected(c), "SelectItems": c.GrantableEndUserPermissionLevels, "SelectItemValue": this.GrantedEndUserPermissionLevel(c), "HasCheckbox": true })
            }
            
            c.Parent = child;
            c.NodeDisabled = !this.permissionService.CheckAdminPermission(element.ClassName, 100);
            c.Expanded = this.boolExpandAll || (this.expandSelected && c.HasChildItemsSelected);
            c.IsSelected = this.IsAdminPermissionSelected(c);
            c.SelectItems = c.GrantableAdminPermissionLevels;
            c.SelectItemValue = this.GrantedAdminPermissionLevel(c);
            c.HasCheckbox = true;
          });
          child.HasChildItemsSelected = child.Children.filter((x: any) => x.IsSelected == true).length > 0;
        });
        element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y: any) => y.IsSelected).length > 0).length > 0;
    });
    }
  }

  public IsNodeDisabled(item: any): Promise<boolean> {
    return this.featuresService.FeatureEnabled(item.ClassName);
  }

  public ExpandAll() {
    this.boolExpandAll = true;
    this.ProcessAllFeatures();
    this.ProcessAllRoleFeatures();
    this.ProcessExplicitFeatures();
  }

  public CollapseAll() {
    this.boolExpandAll = false;
    this.expandSelected = false;
    this.ProcessAllFeatures();
    this.ProcessAllRoleFeatures();
    this.ProcessExplicitFeatures();
  }

  public ExpandSelected() {
    this.boolExpandAll = false;
    this.expandSelected = true;
    this.ProcessAllFeatures();
    this.ProcessAllRoleFeatures();
    this.ProcessExplicitFeatures();
  }

  public ProcessAllFeatures() {
    if(this.allfeatures != null && this.allfeatures.length > 0){
      this.allfeatures.forEach((element: any) => {
        element.IsSelected = this.IsPermissionGranted(element);
        if (element.IsSelected) {
          element.SelectedBy = this.PermissionGrantedBy(element);
        }
        element.Parent = null;
        element.Expanded = this.boolExpandAll || (this.expandSelected && element.HasChildItemsSelected);;
        element.SelectItems = element.GrantableAdminPermissionLevels;
        element.SelectItemValue = this.PermissionGrantedLevel(element);
        element.HasCheckbox = true;
        element.Children?.forEach((child: any) => {
          child.IsSelected = this.IsPermissionGranted(child);
          if (child.IsSelected) {
            child.SelectedBy = this.PermissionGrantedBy(child);
          }
          child.Parent = element;
          child.Expanded = this.boolExpandAll || (this.expandSelected && child.HasChildItemsSelected);
          child.SelectItems = child.GrantableAdminPermissionLevels;
          child.SelectItemValue = this.PermissionGrantedLevel(child);
          child.HasCheckbox = true;
          child.Children?.forEach((c: any) => {
            c.IsSelected = this.IsPermissionGranted(c);
            if (c.IsSelected) {
              c.SelectedBy = this.PermissionGrantedBy(c);
            }
            c.Parent = element;
            c.Expanded = this.boolExpandAll || (this.expandSelected && c.HasChildItemsSelected);;
            c.SelectItems = c.GrantableAdminPermissionLevels;
            c.SelectItemValue = this.PermissionGrantedLevel(c);
            c.HasCheckbox = true;
          });
          child.HasChildItemsSelected = child.Children.filter((x: any) => x.IsSelected == true).length > 0;
        });
        element.HasChildItemsSelected = element.Children.filter((x: any) => x.IsSelected == true || x.Children.filter((y: any) => y.IsSelected).length > 0).length > 0;
      });
    }
  }

  public ProcessAllRoleFeatures() {
    this.grantedRolePermissions = [];
    this.allroles.forEach((element: any) => {
      element.IsSelected = this.IsRoleGranted(element);
      element.HasCheckbox = true;
      element.NodeDisabled = !this.permissionService.CheckRole(element.Name);
      if (element.IsSelected) {
        this.grantedroles.push(element);
        element.RolePermissions.forEach((x: any) => {
          x.Role = element.Name;
          this.grantedRolePermissions.push(x);
        })
      }
    })
  }

  public override AfterModelLoaded(): void {

    if(this.Model.Id != null || this.Model.Id > 0){
      this.NewOrgUser = false;
    }

    if (this.Model.Id == undefined){
      this.Loading();

      if(this.Model.OrganizationId == null)
        this.Model.OrganizationId = this.route.snapshot.queryParams['OrganizationId'];

      if(this.Model.OrganizationId == null) {
        this.apiService.Get<any>("master/organizations").then(result => {
          this.Organizations = result;
          this.StopLoading();
        });
      } else {
        this.apiService.Get<any>("master/organizations/"+this.Model.OrganizationId).then(result => {
          this.Form.patchValue({ "OrganizationId": this.Model.OrganizationId })
          this.Model.OrganizationName = result.Name;
          this.StopLoading();
        });
      }
    }

    var UserId = this.route.snapshot.url[1].path;
    console.log(UserId);

    if (this.Model.Id != undefined){
      this.InitialUser = {FirstName: this.Model.FirstName, LastName: this.Model.LastName, Email: this.Model.Email, Id: this.Model.UserId};
    }
    else if(this.Model.UserId == null){
      if(UserId != null && UserId != '00000000-0000-0000-0000-000000000000'){
        this.Model.UserId = UserId;
        this.apiService.Get<any>("master/users/" + UserId).then(result => {
          this.InitialUser = { FirstName: result.FirstName, LastName: result.LastName, Email: result.Email, Id: result.Id };
        });
      }
    }
    else if(UserId == '00000000-0000-0000-0000-000000000000'){
      this.InitialUser = {}
    }
    else{
      this.apiService.Get<any>("master/users/" + this.Model.UserId).then(result => {
        this.InitialUser = { FirstName: result.FirstName, LastName: result.LastName, Email: result.Email, Id: result.Id };
      });
    }
    
    this.GetOrgFeaturesAndRoles();
  }

  public GetOrgFeaturesAndRoles(){
    if(this.Model.OrganizationId != null){
      this.apiService.Get<any>("features/installed/" + this.Model.OrganizationId).then(result => {
        this.features = result;
        var t = JSON.stringify(this.features);
        this.allfeatures = JSON.parse(t);
        this.ProcessExplicitFeatures();
      });
      this.apiService.Get<any>("master/roles/organization/" + this.Model.OrganizationId).then(result => {
        this.allroles = result;
        this.ProcessAllRoleFeatures();
        this.ProcessAllFeatures();
      });  
    }
  }

  public IsRoleGranted(item: any): boolean {
    var grantedRoles = this.Form.get("Roles")?.value;
    if (grantedRoles == null) {
      return false;
    }
    var t = grantedRoles.filter((x: any) => x.RoleName != null && x.RoleName == item.Name);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public UpdateRoleSelectedItem(item: any) {
    var x = this.IsRoleGranted(item);
    if (x) {
      //item exists in list, so remove.
      item.IsSelected = false;
      var g = this.Form.get("Roles")?.value;
      var index = g.findIndex((x: any) => x.RoleName == item.Name);
      this.FormArray(this.Form, "Roles").removeAt(index);
    }
    else {
      //item does not exist in list, so add.
      var Role = {
        RoleName: item.Name,
        RoleId: item.Id,
      }
      item.IsSelected = true;
      this.AddToFormArray(this.FormArray(this.Form, "Roles"), Role, "Roles");
    }
    this.Form.markAsDirty();
    this.ProcessAllRoleFeatures();
    this.ProcessAllFeatures();
  };

  public override BeforeSave(): void {
  }

  public IsAdminPermissionSelected(item: any): boolean {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    if (grantedPermissions == null) {
      return false;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public IsPermissionGranted(item: any): boolean {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    var grantedRolePermissions = this.grantedRolePermissions;

    if (grantedPermissions == null || grantedRolePermissions == null) {
      return false;
    }
    if (grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      return true;
    }
    else if (grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      return true;
    }
    return false;
  }

  public PermissionGrantedBy(item: any): string {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    var grantedRolePermissions = this.grantedRolePermissions;
    var string = ""

    if (grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      string = " (Granted Permissions"
    }

    if (grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      var rolepermissions = grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
      rolepermissions.forEach((x: any) => {
        if (string != "") {
          string = string + " | " + x.Role
        }
        else {
          string = " (" + x.Role
        }
      })
    }
    if (string != "") {
      string = string + ")"
    }
    return string;
  }

  public PermissionGrantedLevel(item: any): any {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    var grantedRolePermissions = this.grantedRolePermissions;
    var permissionlevel = 0;

    if (grantedPermissions == null || grantedRolePermissions == null) {
      return permissionlevel;
    }
    if (grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      permissionlevel = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName)[0].PermissionLevel;
    }

    if (grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName).length > 0) {
      var secondaryPermission = grantedRolePermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName)[0].PermissionLevel;
      permissionlevel = secondaryPermission > permissionlevel ? secondaryPermission : permissionlevel;
    }

    return permissionlevel;
  }

  public IsEndUserPermissionSelected(item: any): boolean {
    var grantedPermissions = this.Form.get("EndUserPermissions")?.value;
    if (grantedPermissions == null) {
      return false;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  public GrantedAdminPermissionLevel(item: any): number {
    var grantedPermissions = this.Form.get("AdminPermissions")?.value;
    if (grantedPermissions == null) {
      return 0;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return t[0].PermissionLevel;
    }
    else {
      return 0;
    }
  }

  public GrantedEndUserPermissionLevel(item: any): number {
    var grantedPermissions = this.Form.get("EndUserPermissions")?.value;
    if (grantedPermissions == null) {
      return 0;
    }
    var t = grantedPermissions.filter((x: any) => x.Permission != null && x.Permission == item.ClassName);
    if (t.length > 0) {
      return t[0].PermissionLevel;
    }
    else {
      return 0;
    }
  }

  public UpdateExplicitAdminSelectedItem(item: any) {
    var x = this.IsAdminPermissionSelected(item);
      if (x) 
      {
        item.IsSelected = false;
        //item exists in list, so remove.
        var g = this.Form.get("AdminPermissions")?.value;
        var index = g.findIndex((x: any) => x.Permission == item.ClassName);
        this.FormArray(this.Form, "AdminPermissions").removeAt(index);

        //remove parents
        this.RemoveParentAdminPermission(item);
      }
      else {
        //item does not exist in list, so add.
          var AdminPermission = {
            Permission: item.ClassName,
            PermissionLevel: item.GrantableAdminPermissionLevels == null || item.GrantableAdminPermissionLevels.length == 0
              ? 0 : item.GrantableAdminPermissionLevels[0].Level,
          }
          item.IsSelected = true;
          this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), AdminPermission, "AdminPermissions");

          //add parents to list
          this.AddParentAdminPermission(item);
          this.AddPrerequisiteAdminPermissions(item);
      }    
      this.Form.markAsDirty();
      this.ProcessExplicitFeatures();
      this.ProcessAllFeatures();
  };

  public AddParentAdminPermission(item: any) {
    if(item.Parent != null)
      {
        var parent = item.Parent;
        var isParentSelected = this.IsAdminPermissionSelected(parent);
        
        if(!isParentSelected){
        var RolePermission = {
          Permission: parent.ClassName,
          PermissionLevel: parent.GrantableAdminPermissionLevels == null || parent.GrantableAdminPermissionLevels.length == 0
          ? 0 : parent.GrantableAdminPermissionLevels[0].Level,
        }
        
        parent.IsSelected = true;
        this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), RolePermission, "AdminPermissions");
      }
        this.AddParentAdminPermission(parent);
        this.AddPrerequisiteAdminPermissions(parent);
      }
  }

  public RemoveParentAdminPermission(item: any) {
    if(item.Parent != null)
    {
      var parent = item.Parent;
      var isParentSelected = this.IsAdminPermissionSelected(parent);

      if (isParentSelected) {
        parent.HasChildItemsSelected = parent.Children.filter((x: any) => x.IsSelected == true).length > 0;
        parent.IsSelected = parent.HasChildItemsSelected;

        if(!parent.IsSelected){
          var g = this.Form.get("AdminPermissions")?.value;
          var index = g.findIndex((x: any) => x.Permission == item.ClassName);
          this.FormArray(this.Form, "AdminPermissions").removeAt(index);
          this.RemoveParentAdminPermission(parent);
        }
      }
    }
  }

  public AddPrerequisiteAdminPermissions(item: any){
    if(item.PrerequisiteFeatures != null && item.PrerequisiteFeatures.length > 0){
      item.PrerequisiteFeatures.forEach((prereq : any) => {
        //add and select prereq
        prereq.ClassName = prereq.Name;
        var isPrereqSelected = this.IsAdminPermissionSelected(prereq);
        
        if(!isPrereqSelected)
        {
          var allFeatures = this.featuresService.GetFeatures();
          var pre = allFeatures.find((x: any) => x.ClassName == prereq.Name);
          var RolePermission = {
            Permission: pre.ClassName,
            PermissionLevel: pre.GrantableAdminPermissionLevels == null || pre.GrantableAdminPermissionLevels.length == 0
            ? 0 : pre.GrantableAdminPermissionLevels[0].Level,
          }
          var RolePermission = {
            Permission: pre.ClassName,
            PermissionLevel: pre.GrantableAdminPermissionLevels == null || pre.GrantableAdminPermissionLevels.length == 0
            ? 0 : pre.GrantableAdminPermissionLevels[0].Level,
          }

          prereq.IsSelected = true;
          this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), RolePermission, "AdminPermissions");
          this.AddPrerequisiteAdminPermissions(pre);
          this.AddParentAdminPermission(pre);
          };
        });
      }
  }


  public UpdateEndUserSelectedItem(item: any) {
    var x = this.IsEndUserPermissionSelected(item);
    if (x) {
      item.IsSelected = false
      //item exists in list, so remove.
      var g = this.Form.get("EndUserPermissions")?.value;
      var index = g.findIndex((x: any) => x.Permission == item.ClassName);
      this.FormArray(this.Form, "EndUserPermissions").removeAt(index);
    }
    else {
      //item does not exist in list, so add.
      var endUserPermission = {
        Permission: item.ClassName,
        PermissionLevel: 0,
      }

      item.IsSelected = true;
      this.AddToFormArray(this.FormArray(this.Form, "EndUserPermissions"), endUserPermission, "EndUserPermissions");
    }
    this.Form.markAsDirty();
  };


  public UpdateAdminItemSelectedValue(item: any) {
    //item exists in list, so remove.
    var g = this.Form.get("AdminPermissions")?.value;
    var indexItem = g.find((x: any) => x.Permission == item.ClassName);
    var index = g.findIndex((x: any) => x.Permission == item.ClassName)
    this.FormArray(this.Form, "AdminPermissions").removeAt(index);
    indexItem.PermissionLevel = item.SelectItemValue;

    var AdminPermission = {
      Permission: indexItem.Permission,
      PermissionLevel: indexItem.PermissionLevel,
    }
    this.AddToFormArray(this.FormArray(this.Form, "AdminPermissions"), AdminPermission, "AdminPermissions");
    this.Form.markAsDirty();
    this.ProcessAllFeatures();
    this.ProcessExplicitFeatures();
  };


  public UpdateEndUserItemSelectedValue(item: any) {
    //item exists in list, so remove.
    var g = this.Form.get("EndUserPermissions")?.value;
    var indexItem = g.find((x: any) => x.Permission == item.ClassName);
    var index = g.findIndex((x: any) => x.Permission == item.ClassName)
    this.FormArray(this.Form, "EndUserPermissions").removeAt(index);
    indexItem.PermissionLevel = item.SelectItemValue;

    var EndUserPermission = {
      Permission: indexItem.Permission,
      PermissionLevel: indexItem.PermissionLevel,
    }
    this.AddToFormArray(this.FormArray(this.Form, "EndUserPermissions"), EndUserPermission, "EndUserPermissions");
    this.Form.markAsDirty();
  };


  public override Validators(): any {
    return {}
  }


  public AddMagicMoney() {
    this.modalService.addModal(UsermagicmoneyComponent, { User: { OrgUserId: this.Model['Id'] } }, { closeOnEscape: true, closeOnClickOutside: true })
      .subscribe((result: any) => {
        if (result != null) {
          var newMagicMoneyBalance = this.Form.get("MagicMoneyAccountBalance")?.value + result.MagicMoney;
          this.Form.patchValue({ "MagicMoneyAccountBalance": newMagicMoneyBalance })
          this.Form.markAsPristine();
        }
      })
  }

  public RemoveUserPermission(index: number) {
    this.Model.EndUserPermissions.splice(index, 1);
  }

  public RemoveUserAdminPermission(index: number) {
    this.Model.AdminPermissions.splice(index, 1);
  }

  public UpdateFeaturesAndRoles(evt : any){
    this.Model.OrganizationId = evt.target.value;
    this.Form.patchValue({ "OrganizationId": evt.target.value })

    let orgName = this.Organizations?.filter(x => x.Id == evt.target.value)[0].Name;

    this.Model.OrganizationName = orgName;
    this.Form.patchValue({ "OrganizationName": orgName })

    this.GetOrgFeaturesAndRoles();
  }

  public SetIsAdmin(evt: any) {
    this.Model.IsAdmin = evt.target.checked;
    this.Form.patchValue({ "IsAdmin": evt.target.checked })
  }

}
