<button type="{{ButtonType}}" class="button is-primary" [disabled]="Animate">
    <span class="icon-text">
        <span class="icon">
          <i class="fa fa-{{Icon}}" [ngClass]="{'fa-spinner fa-pulse' : Animate}"></i>
        </span>
        <span>
          {{Text}}
        </span>
        <span><ng-content></ng-content></span>
      </span>
</button>