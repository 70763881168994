import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionsService } from '../Services/permissions.service';
import { LoginServiceBase } from './login.service.base';
import { LoginModalComponent } from './login-modal/login-modal.component';


@Injectable({
  providedIn: 'root'
})
export class LoginService implements LoginServiceBase {
  @Output()
  public LoginStatusChanged = new EventEmitter<any>();
  @Output()
  public OrganizationChanged = new EventEmitter<any>();

  constructor(private httpClient: HttpClient, private router: Router, private modalService: SimpleModalService) {
    let cachedUserData = localStorage.getItem("UserData");
    if (cachedUserData != null && cachedUserData.length > 0) {
      let deserializedUserData = JSON.parse(cachedUserData);
      if (deserializedUserData.SID != null) {
        this.UserData = deserializedUserData;
        this.LoginStatusChanged.emit(true);
        this.OrganizationChanged.emit(this.UserData.OrganizationId);
      }
    }
  }
  private UserData = {
    UserId: "00000000-0000-0000-0000-000000000000",
    FirstName: "anonymous user",
    SID: "",
    OrganizationId: "",
    Permissions: []
  };


  public IsLoggedIn() {
    return this.UserData.SID != "";
  }
  public LoggedInFirstname() {
    return this.UserData.FirstName;
  }
  public CurrentToken() {
    return this.UserData.SID;
  }
  public CurrentOrganizationId() {
    return this.UserData.OrganizationId;
  }
  public UserId() {
    return this.UserData.UserId;
  }

  public async DoLogin(username: string, password: string): Promise<any> {
    console.log("Attempting login for " + username);
    try {
      let data: any = await lastValueFrom(this.httpClient.post<any>(environment.serverBaseUrl + "/auth/masteradminlogin", { username: username, password: password }));
      if (data.SID != null) {
        this.UserData = data;
        localStorage.setItem("UserData", JSON.stringify(data));
        console.log("Login Success for " + username);
        this.LoginStatusChanged.emit(true);
        this.router.navigate(['']);
        return { Success: true };
      }
    } catch (error: any) {
      return { Success: false, Error: error.error.Error };
    }
    return { Success: false };
  }
  public DoLogout() {
    this.UserData = {
      UserId: "00000000-0000-0000-0000-000000000000",
      SID: "",
      FirstName: "anonymous user",
      OrganizationId: "",
      Permissions: []
    };
    localStorage.setItem("UserData", "");
    this.LoginStatusChanged.emit(false);
    this.router.navigate(['login']);
  }

  public DoOrganizationSwitch(id: string) {
    let headers = new HttpHeaders().set("authorization", "Bearer " + this.UserData.SID);
    this.httpClient.post<any>(environment.serverBaseUrl + "/auth/currentorganization", { OrganizationId: id }, { headers })
      .subscribe((result : any) => {
        this.UserData.SID = result.SID;
        this.UserData.OrganizationId = id;
        localStorage.setItem("UserData", JSON.stringify(this.UserData));
        this.OrganizationChanged.emit(this.UserData.OrganizationId);
        this.router.navigate(['']);
      });
  }

  private LoginModal: any = null;
  public NotifyAuthExpired() {
    if (this.LoginModal != null) {
      //already a login modal present
      return;
    }
    this.LoginModal = this.modalService.addModal(LoginModalComponent, {})
      .subscribe((result: any) => {
        this.LoginModal = null;
        if (result != null) {

        }
      });
  }

  public DebugBreakAuthToken() {
    this.UserData.SID = "----";
    localStorage.setItem("UserData", JSON.stringify(this.UserData));
  }

}
