import { Component, Input, OnInit } from '@angular/core';
import { Datalistrequestfilter } from '../../datalistrequestfilter';
import { IFilter, IFilterFilters, ISearchTermFilter } from '../ifilter';

@Component({
  selector: 'app-property-filter',
  templateUrl: './property-filter.component.html',
  styleUrls: ['./property-filter.component.scss'],
})
export class PropertyFilterComponent implements OnInit, IFilterFilters {

  @Input()
  public Property: string = "";
  @Input()
  public Comparitor: string = "";
  @Input()
  public Value: string = "";


  public Filters: Datalistrequestfilter[] = [];

  constructor() { 
  }

  ngOnInit(): void {
    if (this.Property != "") {
      this.Filters.push({
        Property: this.Property,
        Comparator: this.Comparitor,
        Value: this.Value
      })
    }


    // this.Filters = [new Datalistrequestfilter(this.Property, this.Comparitor, this.Value)]
  }
}
