import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {

  @Input()
  public Label : string = '';
  @Input()
  public HasAddons : boolean = false;
  @Input()
  public class : string = "";

  constructor() { }

  ngOnInit(): void {
  }


}
