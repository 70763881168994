<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader [Title]="'User: ' + this.Form.get('FirstName')?.value + ' ' + this.Form.get('LastName')?.value">
      <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
    </app-pageheader>

    <article class="panel">
        <app-tabs>
          <app-tab Title="User Details">
            <div class="columns">
              <div class="column is-6">
                <app-field Label="User Id">
                    <div class="control">
                        <p class="input" type="text" disabled>{{this.Form.get('Id')?.value}}</p>
                        <app-validator [For]="this.Form.get('Id')">
                        </app-validator>
                    </div>
                </app-field>
                <app-field Label="First Name">
                    <div class="control">
                        <input formControlName="FirstName" class="input" type="text"/>
                        <app-validator [For]="this.Form.get('FirstName')">
                        </app-validator>
                    </div>
                </app-field>
                <app-field Label="Last Name">
                    <div class="control">
                        <input formControlName="LastName" class="input" type="text"/>
                        <app-validator [For]="this.Form.get('LastName')" >
                        </app-validator>
                    </div>
                </app-field>
                <app-field Label="Email">
                    <div class="control">
                        <input formControlName="Email" class="input" type="text"/>
                        <app-validator [For]="this.Form.get('Email')">
                        </app-validator>
                    </div>
                </app-field>
                <app-field Label="Account Balance">
                  <div class="control">
                    <div app-currency-input formControlName="AccountBalance" [Disabled]="true"></div>
                  </div>
                </app-field>
                <app-field Label="Master Panel Access">
                    <div class="control">
                        <input type="checkbox" formControlName="AllowMasterAccess" (change)="SetIsMasterAdmin($event)"/>
                    </div>
                </app-field>
                <div class="field is-horizontal">
                    <div class="field-label ">
                      <label class="label" disabled>Activated</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="checkbox" disabled>{{Model.HasBeenActivated ? "Yes":"No"}} <a *ngIf="Model.HasBeenActivated == false" (click)="ActivateAccount()">Activate?</a></p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal" *ngIf="Model.HasBeenActivated">
                    <div class="field-label ">
                      <label class="label" disabled>Activated On</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="checkbox" disabled>{{Model.ActivatedOnFriendlyString}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal" *ngIf="Model.HasBeenActivated != true">
                    <div class="field-label ">
                      <label class="label" disabled>Deactivated</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="checkbox" disabled>{{Model.HasBeenDeactivated ? "Account has Been Deactivated" : ""}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="field is-horizontal" *ngIf="Model.HasBeenDeactivated">
                    <div class="field-label ">
                      <label class="label" disabled>Deactivated On</label>
                    </div>
                    <div class="field-body">
                      <div class="control">
                        <p class="checkbox" disabled>{{Model.DeactivatedOnFriendlyString}}</p>
                      </div>
                    </div>
                  </div>
                  <app-field Label="Organization Users">
                    <div class="field is-grouped is-grouped-multiline">
                      <div class="control" *ngFor="let p of Model.OrganizationUsers; let i = index;">
                        <div class="tags has-addons">
                          <p class="tag button" [routerLink]="'/users/' + this.Model.Id + '/organizationuser/edit/' + p.Id" routerLinkActive="active">{{p.OrganizationName}}</p>
                          <p class="tag button" (click)="RemoveOrganizationUser(i)">
                            <span class="icon-text">
                              <span class="icon">
                                <i class="fa fa-trash"></i>
                              </span>
                          </span></p>
                        </div>
                      </div>
                      <div class="control">
                        <div class="tags has-addons">
                          <p class="tag button is-link" [routerLink]="'/users/' + this.Model.Id + '/organizationuser/edit/new'" routerLinkActive="active">
                            <span class="icon-text">
                              <span>Add Organization Access</span>
                                <span class="icon">
                                  <i class="fa fa-plus"></i>
                                </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </app-field>
              </div>
            </div>
          </app-tab>
          </app-tabs>
          </article>

</div>