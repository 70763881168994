import { Injectable } from "@angular/core";
import { lastValueFrom, Observable } from "rxjs";
import { LoginServiceBase } from "../auth/login.service.base";
import { ApiServiceBase } from "./api.service.base"
import { MockData } from "./mock.api.data";

@Injectable({
  providedIn: 'root'
})

export class Mockapiservice implements ApiServiceBase {

  constructor(public loginService: LoginServiceBase) {

  }

  public GetAsync<T>(url: string, ignoreCache?: boolean): Observable<T | Response> {
    console.log("GetAsync: " + url);
    return new Observable((s) => {
      setTimeout(() => {
        switch (url) {
          case "infrastructure/summary":
            s.next(MockData.parkingSummary);
            break;
          case "master/organizations":
            s.next(MockData.userOrgs);
            break;
          case "master/features/installed":
            s.next(MockData.featureInstalled);
            break;
          case "user/alerts/count":
            s.next(MockData.userAlertCount);
            break;
          case "user/alerts":
            s.next(MockData.userAlerts);
            break;
          case "infrastructure/parkinglots":
            s.next(MockData.carCounterSearch);
            break;
          case "infrastructure/carcounters/089916cf-cbcf-4136-bcb2-efc347ed39eb":
            s.next(MockData.carCouterSearch089);
            break;
          default:
            s.next();
            break;
        }
        s.complete();
      }, 3000);
    });


  }
  public Get<T>(url: string): Promise<any> {
    console.log("Get: " + url);
    return lastValueFrom(new Observable((s) => {
      setTimeout(() => {
        switch (url) {
          case "permissions/admin":
            s.next(MockData.permissionAdmin);
            break;
        }
        s.complete();
      }, 3000);
    }));
  }
  public PostAsync<T>(url: string, body: any): Observable<Response | T> {
    console.log("PostAsync: " + url);
    return new Observable((s) => {
      setTimeout(() => {
        switch (url) {
          case "infrastructure/carcounters/searches":
            s.next(MockData.carCounterSearch);
            break;
          case "/infrastructure/debugmessages/searches":
            s.next(MockData.debugMessageSearch);
            break;
          case "infrastructure/debugmessages/decode":
            s.next(MockData.debugMessageDecode);
            break;
          default:
            s.next();
            break;
        }
        s.complete();
      }, 3000);
    });

  }
  public Put<T>(url: string, body: any): Promise<T> {
    console.log("PutAsync: " + url);
    throw new Error("Method not implemented.");
  }
  public Delete<T>(url: string, body: any): Promise<T> {
    console.log("DelAsync: " + url);
    throw new Error("Method not implemented.");
  }
  public Post<T>(url: string, body: object): Promise<any> {
    console.log("Post: " + url);
    throw new Error("Method not implemented.");
  }
}
