<nav class="navbar is-primary is-fixed-top" role="navigation" aria-label="main navigation" *ngIf="loginService.IsLoggedIn()">
    <div *ngIf="ShowBanner" class="environment-banner">
        {{BannerText}}
    </div>
    <div class="navbar-brand">
        <a class="navbar-item logo-container pt-0 pb-0 pl-6 pr-6" href="/">
            <img class="logo mr-5 ml-5" src="/assets/images/Frogparking-Logo-White.png" style="max-height: 50px" />
        </a>
    </div>
    <div id="navbarTop" class="navbar-menu">
        <div class="navbar-start">
            <a class="navbar-item" [routerLink]="organizationListLink">Organizations</a>
            <a class="navbar-item" [routerLink]="userListLink">Users</a>
            <a class="navbar-item" [routerLink]="rolesListLink">Roles</a>
            <a class="navbar-item" [routerLink]="emailsListLink">Email Templates</a>
        </div>
    </div>
    <div class="navbar-menu">
        <div class="navbar-end">
            <div class="navbar-item">
                <div *ngIf="loginService.IsLoggedIn()">
                    Hello {{loginService.LoggedInFirstname()}}!
                </div>
                <div *ngIf="!loginService.IsLoggedIn()">
                    (not logged in)
                </div>
            </div>
            <div class="navbar-item" style="padding-left:50px;">
                <button type="button" style="width:100px" class="button is-small is-outlined is-fullwidth" (click)="LogOut()">Log Out</button>
            </div>
        </div>
    </div>

</nav>