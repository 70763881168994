import { MapBaseLayer } from "@angular/google-maps";
import * as turf from '@turf/turf'


export class Geo {
    public static PolygonCenter(p: number[][]): google.maps.LatLngLiteral {
        if (p == null)
            return { lat: 0, lng: 0 };
        let minX: number = 99999;
        let maxX: number = -99999;
        let minY: number = 99999;
        let maxY: number = -99999;
        for (let i = 0; i < p.length; i++) {
            if (p[i][0] > maxX) maxX = p[i][0];
            if (p[i][1] > maxY) maxY = p[i][1];
            if (p[i][0] < minX) minX = p[i][0];
            if (p[i][1] < minY) minY = p[i][1];
        }

        return { lat: minY + ((maxY - minY) / 2), lng: minX + ((maxX - minX) / 2) };
    }
    public static PolygonCenterAsNumbers(p: number[][]): number[] {
        let center = Geo.PolygonCenter(p);
        return [center.lng, center.lat];
    }

    public static NewInnerPolygon(outerPolygon: number[][], size: number) {
        let center = this.PolygonCenter(outerPolygon);
        return this.NewPolygonAtCenter(center, size);
    }

    public static NewPolygonAtCenter(center: google.maps.LatLng | google.maps.LatLngLiteral | number[], width: number, height ? : number) {
        let w = width / 2;
        let h = height != null ? height/2 : w;
        let x = 0;
        let y = 0;

        if(center == null){
            x = 0;
            y = 0;
        }
        else if (center instanceof google.maps.LatLng) {
            x = center.lng();
            y = center.lat();
        }
        else if (Array.isArray(center)) {
            x = center[0];
            y = center[1];
        }
        else {
            x = center.lng;
            y = center.lat;
        }
        return [[x - w, y - h], [x + w, y - h], [x + w, y + h], [x - w, y + h], [x - w, y - h]];
    }
    public static DoublesToLatLng(doubles: number[][]): google.maps.LatLngLiteral[] {
        if(doubles == null || doubles.length < 2) return [{ lat: 0, lng: 0 }];
        let result = [];
        for (let d of doubles) {
            result.push({ lng: d[0], lat: d[1] });
        }
        return result;
    }

    public static DoublesToSvgPath(doubles: number[][], scale: number = 1): string {
        let result = "M ";
        for (let i = 0; i < doubles.length; i++) {
            result += doubles[i][0] * scale + " " + doubles[i][1] * scale;
            result += " L";
        }
        //back to origin
        result += doubles[0][0] * scale + " " + doubles[0][1] * scale;
        return result;
    }
    public static BisectPoints(p1: number[], p2: number[]) {
        let x = p1[0] + (p2[0] - p1[0]) / 2;
        let y = p1[1] + (p2[1] - p1[1]) / 2;
        return [x, y];
    }
    public static BisectPointsArray(array: number[][]): number[][] {
        let result = [];
        for (let i = 0; i < array.length; i++) {
            result.push(this.BisectPoints(array[i], i == array.length - 1 ? array[0] : array[i + 1]));
        }
        return result;
    }

    public static LeftMost(array: number[][]): number {
        let min = array[0][0];
        for (let point of array) {
            if (point[0] < min) {
                min = point[0];
            }
        }
        return min;
    }
    public static RightMost(array: number[][]): number {
        let max = array[0][0];
        for (let point of array) {
            if (point[0] > max) {
                max = point[0];
            }
        }
        return max;
    }
    public static TopMost(array: number[][]): number {
        let min = array[0][1];
        for (let point of array) {
            if (point[1] < min) {
                min = point[1];
            }
        }
        return min;
    }
    public static BottomMost(array: number[][]): number {
        let max = array[0][1];
        for (let point of array) {
            if (point[1] > max) {
                max = point[1];
            }
        }
        return max;
    }
    public static GetPolygonPointBounds(item: any): Bounds {
        if(item == null) return new Bounds(0,0,0,0);
        let left, right, top, bottom: number | null = null;
        if (Array.isArray(item)) {
            for(let i of item){
                if(i.PolygonPoints){
                    for (let point of i.PolygonPoints) {
                        if (top == null || point[1] < top) {
                            top = point[1];
                        }
                        if (bottom == null || point[1] > bottom) {
                            bottom = point[1];
                        }
                        if (left == null || point[0] < left) {
                            left = point[0];
                        }
                        if (right == null || point[0] > right) {
                            right = point[0];
                        }
                    }
                }
                else if(Array.isArray(i)){
                        if (top == null || i[1] < top) {
                            top = i[1];
                        }
                        if (bottom == null || i[1] > bottom) {
                            bottom = i[1];
                        }
                        if (left == null || i[0] < left) {
                            left = i[0];
                        }
                        if (right == null || i[0] > right) {
                            right = i[0];
                        }
                }
            }
        }
        else {
            for (let point of item.PolygonPoints) {
                if (top == null || point[1] < top) {
                    top = point[1];
                }
                if (bottom == null || point[1] > bottom) {
                    bottom = point[1];
                }
                if (left == null || point[0] < left) {
                    left = point[0];
                }
                if (right == null || point[0] > right) {
                    right = point[0];
                }
            }
        }
        return new Bounds(left ?? 0, top ?? 0, right ?? 0, bottom ?? 0);
    }

    public static DirectionFromCenter(point: number[], array: number[][]): string {

        let center = this.PolygonCenterAsNumbers(array);
       return this.DirectionFromPoint(point, center);
    }
    public static DirectionFromPoint(pointTo : number[], pointFrom : number[]){
        let x = pointTo[0] - pointFrom[0];
        let y = pointFrom[1] - pointTo[1];
        let angle = Math.atan2(y, x) + Math.PI;

        let directions = ["w", "sw", "s", "se", "e", "ne", "n", "nw", "w"];
        let index = Math.round((angle) / (Math.PI / 4));
        return directions[index];
    }
    public static AngleFromPoint(pointTo : number[], pointFrom : number[]){
        return (Math.atan2(pointTo[1] - pointFrom[1], pointTo[0] - pointFrom[0]) * 180 / Math.PI)+90;
    }
    public static DistanceBetweenPoints(pointTo : number[], pointFrom: number[]) : number{
        return Math.sqrt( Math.pow((pointFrom[0]-pointTo[0]), 2) + Math.pow((pointFrom[1]-pointTo[1]), 2) );
    }
    public static RotatePointsAroundOrigin(points: number[][], origin : number[], angleDegrees : number) : number[][]{
        let result = [];
        for(let point of points){
            result.push(this.RotatePointAroundOrigin(point, origin, angleDegrees));
        }
        return result;
    }
    public static RotatePointAroundOrigin(point: number[], origin: number[], angleDegrees : number) : number[]{
        if(angleDegrees == 0)
            return point;
        var radians = (Math.PI / -180) * (angleDegrees-90);
        var cos = Math.cos(radians);
        var sin = Math.sin(radians);
        var nx = (cos * (point[0] - origin[0])) + (sin * (point[1] - origin[1])) + origin[0];
        var ny = (cos * (point[1] - origin[1])) - (sin * (point[0] - origin[0])) + origin[1];
        return [nx, ny];
    }

    
}

export class Bounds {
    public constructor(left: number, top: number, right: number, bottom: number) {
        this.Left = left;
        this.Right = right;
        this.Top = top;
        this.Bottom = bottom;
        this.Height = bottom-top;
        this.Width = right-left;
        this.Center = [this.Width/2 + left, this.Height/2 + top];
    }
    public Left: number = 0;
    public Right: number = 0;
    public Top: number = 0;
    public Bottom: number = 0;
    public Width : number = 0;
    public Height: number = 0;
    public Center : number[] = [0,0];

    public SvgPath(scale : number) : string{
        let points= [[this.Left, this.Top], [this.Right, this.Top], [this.Right, this.Bottom], [this.Left, this.Bottom]];
        return Geo.DoublesToSvgPath(points, scale);
    }
    public Offset(left: number, top: number){
        this.Left += left;
        this.Right += left;
        this.Top += top;
        this.Bottom += top;
    }
    public Scale(scale : number){
        this.Left *= scale;
        this.Right *= scale;
        this.Top *= scale;
        this.Bottom *= scale;
    }
    public Contains(point : number[]) : boolean{
        if(point.length == 0) return false;
        return this.Left <= point[0] && this.Right >= point[0] && this.Top <= point[1] && this.Bottom >=  point[1];
    }
    public ContainsPolygon(points : number[][]) : boolean{
        if(points.length == 0) return false;
        for(let point of points){
            if(!(this.Left <= point[0] && this.Right >= point[0] && this.Top <= point[1] && this.Bottom >=  point[1])){
                return false;
            }
        }
        return true;
    }
}
