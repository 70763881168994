import { Component, Input, OnInit } from '@angular/core';
import { Busyable } from '../busyable';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input()
  public Parent : Busyable | null = null;
  @Input()
  Mode : string = "spinner";

  public Visible : boolean = false;

  constructor() { }

  ngOnInit(): void {

    this.Parent?.LoadingStarted.subscribe(x => {
      this.Visible = true;
    });
    this.Parent?.LoadingEnded.subscribe(x => {
      this.Visible = false;
    });

    if(this.Parent != null)
      this.Visible = this.Parent.IsLoading;
  }

}
