import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LoginService } from 'src/app/auth/login.service';
import { FeaturesService } from 'src/app/Services/features.service';
import { OrganizationsService } from 'src/app/Services/organizations.service';
import { PermissionsService } from 'src/app/Services/permissions.service';
import { LoginServiceBase } from 'src/app/auth/login.service.base';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.scss']
})
export class MainmenuComponent implements OnInit {

  public TopMenuItems: Array<any> = [];
  public ShowOrganizationsDropdown: boolean = false;
  public organizationListLink: string = "/organizations";
  public userListLink: string = "/users";
  public rolesListLink: string = "/roles";
  public emailsListLink: string = "/emailtemplates";
  public ShowBanner = environment.bannerText != null;
  public BannerText = environment.bannerText;  

  constructor(public loginService: LoginServiceBase, private router: Router, private featuresService: FeaturesService, private permissionsService: PermissionsService, private organizationsService: OrganizationsService) {
    this.loginService.LoginStatusChanged.subscribe(loggedIn => {
      if (loggedIn == true) {
      }
    });
    this.featuresService.MenuChanged.subscribe(x => {
      console.log("Main menu received MenuChanged event");
    });
  }

  ngOnInit(): void {
    if (this.loginService.IsLoggedIn() == false) {
      this.router.navigate(['login']);
    }
  }

  public LogOut() {
    this.loginService.DoLogout();
  }

  public SwitchToOrganization(id: string) {
    this.ShowOrganizationsDropdown = false;
    this.loginService.DoOrganizationSwitch(id);
  }
}