import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LoginComponent } from './auth/login/login.component';
import { MainmenuComponent } from './menu/mainmenu/mainmenu.component';
import { LoginStatusComponent } from './auth/login-status/login-status.component';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { DatalistviewComponent } from './components/datalistview/datalistview.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { SimpleModalModule } from 'ngx-simple-modal';
import { PageheaderComponent } from './components/pageheader/pageheader.component';
import { TreeComponent } from './components/tree/tree.component';
import { TreenodeComponent } from './components/tree/treenode/treenode.component';
import { ActionbuttonComponent } from './components/buttons/actionbutton/actionbutton.component';
import { AceEditorModule } from 'ng2-ace-editor';
import { CodeeditorComponent } from './components/codeeditor/codeeditor.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PendingChangesGuard } from './components/guards/pendingchangesguard';
import { UnsavedchangesdialogComponent } from './components/dialogs/unsavedchangesdialog/unsavedchangesdialog.component';
import { OrgswitchComponent } from './auth/orgswitch/orgswitch.component';
import { LoadingspinnerComponent } from './components/loader/loadingspinner/loadingspinner.component';
import { ValidatedInputComponent } from './components/validated-input/validated-input.component';
import { LoginModalComponent } from './auth/login-modal/login-modal.component';
import { LoginformComponent } from './auth/loginform/loginform.component';
import { UnvalidatedInputComponent } from './components/unvalidated-input/unvalidated-input.component';
import { ValidatedIfInputComponent } from './components/validated-if-input/validated-if-input.component';
import { ValidatorComponent } from './components/validator/validator.component';
import { FieldComponent } from './components/field/field.component';
import { CurrencyInputComponent } from './components/inputs/currency-input/currency-input.component';
import { FormSaveButtonComponent } from './components/buttons/form-save-button/form-save-button.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { UserselectorComponent } from './components/inputs/userselector/userselector.component';
import { MapInputComponent } from './components/inputs/map-input/map-input.component';
import { ValidationsummaryComponent } from './components/validationsummary/validationsummary.component';
import { LoadingcubeComponent } from './components/loader/loadingcube/loadingcube.component';
import { FirmwareselectorComponent } from './components/dialogs/firmwareselector/firmwareselector.component';
import { HardwaremessagesviewerComponent } from './components/hardwaremessagesviewer/hardwaremessagesviewer.component';
import { HardwaremessagestableComponent } from './components/hardwaremessagesviewer/hardwaremessagestable/hardwaremessagestable.component';
import { ColorpickerComponent } from './components/inputs/colorpicker/colorpicker.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgChartsModule } from 'ng2-charts';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationComponent } from './organizations/organization/organization.component';
import { EditorganizationComponent } from './organizations/editorganization/editorganization.component';
import { TreenodeselectComponent } from './components/tree/treenodeselect/treenodeselect.component';
import { UserComponent } from './users/user/user.component';
import { EditorganizationuserComponent } from './users/editorganizationuser/editorganizationuser.component';
import { TreenoderecursiveComponent } from './components/tree/treenoderecursive/treenoderecursive.component';
import { UsermagicmoneyComponent } from './users/edituser/usermagicmoney/usermagicmoney.component';
import { RolesComponent } from './roles/roles.component';
import { RoleComponent } from './roles/role/role.component';
import { EditRoleComponent } from './roles/editrole/editrole.component';
import { DateFilterComponent } from './components/filters/date-filter/date-filter.component';
import { RatesFilterComponent } from './components/filters/rates-filter/rates-filter.component';
import { SearchFilterComponent } from './components/filters/search-filter/search-filter.component';
import { FilterSetComponent } from './components/filters/filter-set/filter-set.component';
import { SubsetFilterComponent } from './components/filters/subset-filter/subset-filter.component';
import { AdminFilterComponent } from './components/filters/admin-filter/admin-filter.component';
import { SearchButtonComponent } from './components/filters/search-button/search-button.component';
import { UsersComponent } from './users/users.component';
import { EdituserComponent } from './users/edituser/edituser.component';
import { PropertyFilterComponent } from './components/filters/property-filter/property-filter.component';
import { DataListAddButton } from './components/buttons/datalist-add-button/datalist-add-button.component';
import { EmailsComponent } from './emailmanagement/emails.component';
import { EditEmailComponent } from './emailmanagement/editemail/editemail.component';
import { AddRoleUserComponent } from './roles/addroleuser/addroleuser.component';

@NgModule({
  declarations: [
    PropertyFilterComponent,
    UsersComponent,
    EdituserComponent,
    EditRoleComponent,
    EditorganizationComponent,
    OrganizationComponent,
    OrganizationsComponent,
    EditorganizationComponent, 
    EditorganizationuserComponent,
    AppComponent,
    LoginComponent,
    MainmenuComponent,
    LoginStatusComponent,
    HomeComponent,
    DatalistviewComponent,
    TabsComponent,
    TabComponent,
    PageheaderComponent,
    TreeComponent,
    TreenodeComponent,
    ActionbuttonComponent,
    CodeeditorComponent,
    LoaderComponent,
    UnsavedchangesdialogComponent,
    OrgswitchComponent,
    LoadingspinnerComponent,
    ValidatedInputComponent,
    LoginModalComponent,
    LoginformComponent,
    UnvalidatedInputComponent,
    ValidatedIfInputComponent,
    ValidatorComponent,
    FieldComponent,
    CurrencyInputComponent,
    FormSaveButtonComponent,
    UserselectorComponent,
    MapInputComponent,
    ValidationsummaryComponent,
    LoadingcubeComponent,
    FirmwareselectorComponent,
    HardwaremessagesviewerComponent,
    HardwaremessagestableComponent,
    ColorpickerComponent,
    UsermagicmoneyComponent,
    RolesComponent,
    UserComponent,
    TreenoderecursiveComponent,
    TreenodeselectComponent,
    RoleComponent,
    EditRoleComponent,
    DateFilterComponent,
    RatesFilterComponent,
    SearchFilterComponent,
    FilterSetComponent,
    SubsetFilterComponent,
    AdminFilterComponent,
    SearchButtonComponent,
    DataListAddButton,
    EmailsComponent,
    EditEmailComponent,
    AddRoleUserComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    AceEditorModule,
    ColorPickerModule,
    AutocompleteLibModule,
    NgxSliderModule,
    NgChartsModule,
    SimpleModalModule.forRoot({ container: 'modal-container' }, {
      bodyClass: '',
      draggableClass: '',
      closeOnEscape: true,
      closeOnClickOutside: false,
      wrapperDefaultClasses: 'dialog fade-anim',
      wrapperClass: 'fade-anim in',
      animationDuration: 300,
      autoFocus: true,
      draggable: false
    }
    ),
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    PendingChangesGuard,
    environment.apiTarget
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

