<div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card" style="min-width: 800px; background: linear-gradient(#3b6d40, #2E8637); color: white;">
      <header class="modal-card-head" style="background: none;border-bottom: none;">
        <p class="modal-card-title" style="color: white;">Your session has expired, please log in again</p>
      </header>
      <section class="modal-card-body" style="background: none;">
        <app-loginform RedirectUrl="/systemadmin"></app-loginform> 
      </section>
      <footer class="modal-card-foot" style="background: none;border-top: none;">
      </footer>
    </div>
  </div>