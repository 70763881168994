import { EventEmitter, Injectable, Output } from '@angular/core';
import { LoginService } from '../auth/login.service';
import { ApiServiceBase } from './api.service.base';
import { ApiService } from './api.service';
import { LoginServiceBase } from '../auth/login.service.base';
import { v4 as uuidv4 } from 'uuid';
import { resolve } from 'dns';
import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  @Output()
  public PermissionSetChanged = new EventEmitter<any>();

  private Permissions: any[] = [];
  private Roles: any[] = [];
  public SessionIdentifier: any = "";
  private AlreadyCheckedAdminPerms: boolean = false;

  constructor(private apiService: ApiServiceBase, private loginService: LoginServiceBase) {
    loginService.OrganizationChanged.subscribe(orgId => {
      console.log("Permission Service received Org Changed event");
      this.RefreshPermissions().then();
    });
    loginService.LoginStatusChanged.subscribe(x => {
      console.log("Permission Service received Login Status Changed event");
      this.RefreshPermissions().then();
    });

    if (loginService.IsLoggedIn()) {
      console.log("User is already logged in during PermissionService construction, refreshing Permissions from server");
      this.RefreshPermissions().then(x => {
        var sessionId = uuidv4();
        this.SessionIdentifier = "_" + sessionId.substring(sessionId.length - 6);
      })
    };
  }

  private PermissionsPromise: Promise<any> | null = null;
  private async RefreshPermissions() {
    return null;
    if (this.PermissionsPromise != null)
      return this.PermissionsPromise;

    this.PermissionsPromise = new Promise<any>(async (resolve, reject) => {
      console.log("Permissions Service refreshing permissions from server");
      await this.apiService.Get<any>("permissions/admin").then(x => {
        let permissions = x.Permissions;
        let roles = x.Roles;

        if (permissions != null) {
          console.log("Permissions Service got permissions from server");
          this.Permissions = permissions;
          this.PermissionSetChanged.emit();
        }

        if (roles != null) {
          console.log("Permissions Service got roles from server");
          this.Roles = roles;
        }
        resolve(x);
        this.PermissionsPromise = null;
      })
    });
    return this.PermissionsPromise;
  }

  public async PermissionExists(permission: string) {
    if (this.Permissions.length == 0 && !this.AlreadyCheckedAdminPerms) {
      await this.RefreshPermissions();
      this.AlreadyCheckedAdminPerms = true;
    }
    let matches = this.Permissions.filter((x: any) => x.Permission == permission);
    if (matches.length == 0) {
      return false;
    }
    return true;
  }

  public GetSessionIdentifier(): any {
    return this.SessionIdentifier;
  }

  public CheckRole(role: string) {
    if (this.Roles.length == 0) {
      return false;
    }

    let matches = this.Roles.filter((x: any) => x.RoleName == role);
    if (matches.length > 0) {
      return true;
    }
    return false;
  }

  public async CheckAdminPermission(permission: string, requiredLevel: number | null): Promise<boolean> {
     if (this.Permissions.length == 0&& !this.AlreadyCheckedAdminPerms) {
      await this.RefreshPermissions();
      this.AlreadyCheckedAdminPerms = true;
    }

    if (requiredLevel == null) {
      return true;
    }

    let matches = this.Permissions.filter((x: any) => x.Permission == permission);
    if (matches.length == 0) {
      console.log("No match found: " + permission)
      return false;
    }

    if (requiredLevel != null && matches[0].PermissionLevel >= requiredLevel) {
      return true;
    }

    return false;
  }
}
