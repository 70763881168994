<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
    <app-pageheader Title="Organization Access User">
        <app-form-save-button [Parent]="this" [Form]="Form" (click)="Save()">
        </app-form-save-button>
    </app-pageheader>
<div class="columns">
    <div class="column is-12">
        <article class="panel">
        <app-tabs>
            <app-tab Title="Details">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="User">
                        <div class="control" *ngIf="!(this.Model.UserId == null || this.Model.UserId == '00000000-0000-0000-0000-000000000000') else addUser">
                            <input class="input" [value]="InitialUser.LastName + ', ' + InitialUser.FirstName" disabled />
                            <app-userselector formControlName="UserId" [User]="InitialUser" [CanClear]="false" hidden>
                            </app-userselector>
                        </div>

                        <ng-template #addUser>
                            <div class="control">
                                <app-userselector formControlName="UserId" [User]="InitialUser" required>
                                </app-userselector>
                            </div>
                        </ng-template>
                    </app-field>
                    <app-field Label="Organization">
                        <div class="control">
                            <input class="input" disabled="true" *ngIf="!this.NewOrgUser || (this.Model.UserId == null || this.Model.UserId == '00000000-0000-0000-0000-000000000000'); else addOrg" [value]="this.Model.OrganizationName"/>
                            <ng-template #addOrg>
                            <select class="input" formControlName="OrganizationId" placeholder="Select an Organization" (change)="UpdateFeaturesAndRoles($event)">
                                <option *ngFor="let f of Organizations" [value]="f.Id">{{f.Name}}</option>
                            </select>
                            </ng-template>
                        </div>
                    </app-field>
                    <app-field Label="AccountBalance">
                        <div class="control">
                          <div app-currency-input formControlName="AccountBalance" [Disabled]="true"></div>
                        </div>
                    </app-field>
                    <app-field Label="Magic Money Balance">
                        <div class="field" [ngClass]="{'has-addons': NewOrgUser == false}" style="width:100%;">
                            <div app-currency-input formControlName="MagicMoneyAccountBalance" class="control" style="width:100%;" [Disabled] = "true">
                            </div>
                            <div class="control">
                                <a *ngIf="NewOrgUser == false" class="button is-primary" (click)="AddMagicMoney()">Add Magic Money</a>
                            </div>

                        </div>
                    </app-field>
                    <app-field Label="Admin Access">
                        <div class="control">
                            <input type="checkbox" style="vertical-align:middle" [checked]="this.Model.IsAdmin" (change)="SetIsAdmin($event)" />
                        </div>
                    </app-field>
                </div>
            </div>
            </app-tab>
            <app-tab Title="Permissions">
                <app-tabs>
                    <app-tab Title="Granted Roles" *ngIf="Model.IsAdmin == true">
                        <app-tree formArrayName="Roles">
                            <app-treenoderecursive [recursiveList]="allroles" (UpdateItem)="UpdateRoleSelectedItem($event)">
                            </app-treenoderecursive>
                        </app-tree>
                    </app-tab>
                    <app-tab Title="Granted Permissions" *ngIf="Model.IsAdmin == true">
                        <div class="columns">
                            <div class="column is-5">
                                <div style="margin-bottom:10px;">
                                    <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
                                    <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
                                    <a (click)="ExpandSelected()" >Expand Selected</a></span>
                                </div>
                                <app-tree formArrayName="AdminPermissions">
                                    <app-treenoderecursive [recursiveList]="features" (ItemClicked)="ClickItem($event)" (UpdateItem)="UpdateExplicitAdminSelectedItem($event)" (UpdateItemSelectedValue)="UpdateAdminItemSelectedValue($event)">
                                    </app-treenoderecursive>
                                </app-tree>
                            </div>
                            <div class="column is-7">
                                <div class="box content" style="height:100%">
                                    <h3 class="has-text-weight-bold">{{SelectedFeatureName}}</h3>
                                    <p class="has-text-weight-light- is-italic">{{SelectedFeatureDefinition}}</p>
                                    <h6 *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">Enables Menu Items</h6>
                                    <ul *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">
                                      <li *ngFor="let i of SelectedFeatureRoutes">
                                        {{i.MenuPath}}
                                      </li>
                                    </ul>
                                    <h6 *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0">Feature Prerequisites</h6>
                                    <ul *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0"> 
                                      <li *ngFor="let i of SelectedFeaturePrerequisites">
                                        {{i.Name}}
                                      </li>
                                    </ul>
                                  </div>
                            </div>
                        </div>
                    </app-tab>
                    <app-tab Title="End User Permissions" *ngIf="endUserfeatures != null && endUserfeatures.length > 0">
                        <app-tree formArrayName="AdminPermissions">
                            <app-treenoderecursive [recursiveList]="endUserfeatures" (UpdateItem)="UpdateEndUserSelectedItem($event)" (UpdateItemSelectedValue)="UpdateEndUserItemSelectedValue($event)">
                            </app-treenoderecursive>
                        </app-tree>
                    </app-tab>
                    <app-tab Title="Resulting Permission Set">
                        <div style="margin-bottom:10px;">
                            <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
                            <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
                            <a (click)="ExpandSelected()" >Expand Selected</a></span>
                        </div>
                        <app-tree>
                            <app-treenoderecursive [recursiveList]="allfeatures" [NodeDisabled]="true">
                            </app-treenoderecursive>
                        </app-tree>
                    </app-tab>
                </app-tabs>
            </app-tab>
        </app-tabs>
        </article>
    </div>
</div>
