<app-loader [Parent]="this"></app-loader>
<div *ngIf="FormLoaded">
<app-pageheader [Title]="userInfo.FirstName + ' ' + userInfo.LastName">
    <button class="button is-link" routerLink="/users/edit/{{userInfo.Id}}" routerLinkActive="active">
      <span class="icon-text">
        <span>Edit</span>
          <span class="icon">
            <i class="fa fa-chevron-right"></i>
          </span>
        </span>
    </button>
</app-pageheader>
<div class="columns">
  <div class="column is-12">
  <article class="panel">
    <app-tabs>
      <app-tab Title="User Details">
        <div class="columns">
          <div class="column is-6">
              <app-field Label="Id">
                <div class="control">
                  <input class="input" [value]="userInfo.Id" disabled />
                </div>
              </app-field>
              <app-field Label="Full Name">
                <div class="control">
                  <input class="input" [value]="userInfo.FirstName + ' ' + userInfo.LastName" disabled />
                </div>
              </app-field>
              <app-field Label="Email">
                <div class="control">
                  <input class="input" [value]="userInfo.Email" disabled />
                </div>
              </app-field>
              <app-field Label="Admin Access">
                <div class="control">
                  <input class="input" [value]="userInfo.AllowMasterAccess ? 'Granted':'Not Granted'" disabled />
                </div>
              </app-field>
              <app-field Label="Activated">
                <div class="control">
                  <input class="input" [value]="userInfo.HasBeenActivated ? 'Activated on ' + userInfo.ActivatedOnFriendlyString : 'Not Activated' " disabled />
                </div>
              </app-field>
              <app-field Label="Deactivated">
                <div class="control">
                  <input class="input" [value]="userInfo.HasBeenDeactivated ? 'Deactivated on ' + userInfo.DeactivatedOnFriendlyString : ''" disabled />
                </div>
              </app-field>
              <app-field *ngIf="userInfo.HasBeenRemoved" Label="Removed">
                <div class="control">
                  <input class="input" [value]="userInfo.HasBeenRemoved ? 'Removed on ' + userInfo.RemovedOnFriendlyString : ''" disabled />
                </div>
              </app-field>
              <app-field Label="Account Balance">
                <div class="control">
                  <input class="input" [value]="userInfo.AccountBalance" disabled />
                </div>
              </app-field>
              <app-field Label="Magic Money Balance">
                <div class="control">
                  <input class="input" [value]="userInfo.MagicMoneyBalance" disabled />
                </div>
              </app-field>
              <app-field Label="Joined Via Organization">
                <div class="control">
                  <input class="input" [value]="userInfo.JoinedViaOrganizationName" disabled />
                </div>
              </app-field>
              </div>
              </div>
      </app-tab>
      <app-tab Title="Organization Access">
        <app-datalistview [Columns]="UserColumns" [ShowMap]="false" ApiUrl="master/organizationusers/searches" 
        [AllowEdit]="true" EditUrl="/users/:UserId/organizationuser/edit/:Id">
            <app-filter-set>
              <div app-search-filter></div>
              <div app-search-button></div>
              <app-property-filter Property="UserId" Comparitor="==" [Value]="userInfo.Id"></app-property-filter>

              <button class="button is-link" style="float:right; margin-left:20px" [routerLink]="'/users/' + userInfo.Id + '/organizationuser/edit/new'" routerLinkActive="active">
                <span class="icon-text">
                  <span>Add</span>
                    <span class="icon">
                      <i class="fa fa-plus"></i>
                    </span>
                  </span>
              </button>
            </app-filter-set>
        </app-datalistview>
      </app-tab>
    </app-tabs>
  </article>
</div>
</div>