<app-loader [Parent]="this"></app-loader>
<div [formGroup]="Form" *ngIf="FormLoaded">
<app-pageheader [Title]="'Role: ' + this.Form.get('Name')?.value">
  <app-form-save-button [Form]="Form" [Parent]="this" (click)="Save()"></app-form-save-button>
</app-pageheader>

<app-tabs>
  <app-tab Title="Details">
    <div>
      <div class="column is-6">
        <div class="column">
          <app-field Label="Role Name">
            <div class="control">
                <input formControlName="Name" class="input" type="text" />
                <app-validator [For]="this.Form.get('Name')">
                </app-validator>
            </div>
          </app-field>
        </div>
        <div class="column">
          <app-field Label="Organization">
            <div class="control">
                <input class="input"  disabled="true" *ngIf="this.Model.OrganizationId != null && !this.newRole" [value]="this.Model.OrganizationName"/>
                <select class="input" [value]="this.Model.OrganizationId" *ngIf="this.Model.OrganizationId == null || this.newRole" placeholder="Select an Organization" (change)="ChangeOrgSelected($event)">
                    <option *ngFor="let f of Organizations" [value]="f.Id">{{f.Name}}</option>
                </select>
            </div>
          </app-field>
      </div>
    </div>  
    </div>
  </app-tab>
      <app-tab Title="Permissions">
        <div class="columns">
          <div class="column is-5">
            <div style="margin-bottom:10px;">
              <span><a (click)="ExpandAll()">Expand All</a><a> | </a>
              <a (click)="CollapseAll()" >Collapse All</a><a> | </a>
              <a (click)="ExpandSelected()" >Expand Selected</a></span>
            </div>
              <app-tree formArrayName="Features">
                  <app-treenoderecursive [recursiveList]="features" (UpdateItem)="UpdateAdminSelectedItem($event)" (ItemClicked)="ClickItem($event)" (UpdateItemSelectedValue)="UpdateAdminItemSelectedValue($event)">
                  </app-treenoderecursive>
              </app-tree>
          </div>
          <div class="column is-7">
            <div class="box content" style="height:100%">
              <h3 class="has-text-weight-bold">{{SelectedFeatureName}}</h3>
              <p class="has-text-weight-light- is-italic">{{SelectedFeatureDefinition}}</p>
              <h6 *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">Enables Menu Items</h6>
              <ul *ngIf="SelectedFeatureRoutes != null && SelectedFeatureRoutes.length > 0">
                <li *ngFor="let i of SelectedFeatureRoutes">
                  {{i.MenuPath}}
                </li>
              </ul>
              <h6 *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0">Feature Prerequisites</h6>
              <ul *ngIf="SelectedFeaturePrerequisites != null && SelectedFeaturePrerequisites.length > 0"> 
                <li *ngFor="let i of SelectedFeaturePrerequisites">
                  {{i.Name}}
                </li>
              </ul>
            </div>
          </div>
        </div>
  </app-tab>
</app-tabs>
