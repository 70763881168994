<app-loader [Parent]="this"></app-loader>
<div *ngIf="FormLoaded">
    <app-pageheader [Title]="orgInfo.Name + ' (' + orgInfo.HostName + ')'">
        <button class="button is-link" routerLink="/organizations/edit/{{orgInfo.Id}}" routerLinkActive="active">
            <span class="icon-text">
                <span>Edit</span>
                <span class="icon">
                    <i class="fa fa-chevron-right"></i>
                </span>
            </span>
        </button>
    </app-pageheader>
    <div *ngIf="orgInfo != null">
    <article class="panel">
    <app-tabs>
        <app-tab Title="Organization Information">
            <div class="columns">
                <div class="column is-6">
                    <app-field Label="Id">
                        <div class="control">
                            <input class="input" [value]="orgInfo.Id" disabled/>
                        </div>
                    </app-field>
                    <app-field Label="Short Id">
                        <div class="control">
                            <input class="input" [value]="orgInfo.ShortId" disabled/>
                        </div>
                    </app-field>
                    <app-field Label="City">
                        <div class="control">
                            <input class="input" [value]="orgInfo.City" disabled/>
                        </div>
                    </app-field>
                    <app-field Label="TimeZone">
                        <div class="control">
                            <input class="input" [value]="orgInfo.TimeZoneDisplayName" disabled/>
                        </div>
                    </app-field>
                    <app-field Label="Currency">
                        <div class="control">
                            <input class="input" [value]="orgInfo.CurrencyCode" disabled/>
                        </div>
                    </app-field>
                </div>
                <div class="column is-6">
                    <google-map [options]="MapOptions" height="100%" width="100%" [zoom]="18">
                        <map-marker *ngFor="let marker of markers" [position]="marker.position"></map-marker>
                    </google-map>
                </div>
            </div>
        </app-tab>
        <app-tab Title="Enabled Feature Tree">
            <div style="padding-left:50px; margin-bottom:20px;">
                <app-tree>
                    <app-treenoderecursive [recursiveList]="features" [NodeDisabled]="true">
                    </app-treenoderecursive>
                </app-tree>
                <div class="columns">
                </div>
            </div>
        </app-tab>
        <app-tab Title="Roles List">
            <app-datalistview [Columns]="RoleColumns" [ShowMap]="false" ApiUrl="master/roles/search" [AllowEdit]="true" EditUrl="roles/:id">
            <app-filter-set>
            <div app-search-filter></div>
            <div app-search-button></div>
            <app-property-filter Property="OrganizationId" Comparitor="==" [Value]="orgInfo.Id"></app-property-filter>

            <button class="button is-link" style="float:right; margin-left:20px" (click)="AddRole()">
                <span class="icon-text">
                  <span>Add</span>
                    <span class="icon">
                      <i class="fa fa-plus"></i>
                    </span>
                  </span>
              </button>

            </app-filter-set>
        </app-datalistview>
        </app-tab>
        <app-tab Title="User List">
            <app-datalistview [Columns]="UserColumns" [ShowMap]="false" ApiUrl="master/organizationusers/searches"
             [AllowEdit]="true" EditUrl="/users/:UserId/organizationuser/edit/:Id">
            <app-filter-set>
            <div app-search-filter></div>
            <div app-search-button></div>
            <app-property-filter Property="OrganizationId" Comparitor="==" [Value]="orgInfo.Id"></app-property-filter>

            <button class="button is-link" style="float:right; margin-left:20px" (click)="AddOrganizationAccess()">
                <span class="icon-text">
                  <span>Add</span>
                    <span class="icon">
                      <i class="fa fa-plus"></i>
                    </span>
                  </span>
              </button>
            </app-filter-set>
        </app-datalistview>
        </app-tab>
    </app-tabs>
    </article>
    </div>
</div>