import { Component, OnInit } from '@angular/core';
import { Datalistviewcolumn } from 'src/app/components/datalistviewcolumn';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  
  constructor() { }

  public Columns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("Name", "Name"),
    new Datalistviewcolumn("Organization", "OrganizationName")
  ];

  ngOnInit(): void {
  }


}
