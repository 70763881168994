<div class="is-fullwidth">
    <div [ngClass]="{'search-border': title != null}">
        <div [ngClass]="{'search-header': title != null}">{{title}}</div>
        <div>
            <div class="level-left field has-addons p-2">
                <ng-content></ng-content>
            </div>
        </div>

        <div style="align-self: stretch;">
            <ng-content select="[advanced]"></ng-content>
        </div>
    </div>
</div>