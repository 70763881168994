<section class="has-text-centered">
    <div class="hero-body">
      <div class="reports">
              <div class="card" routerLink="/users" routerLinkActive="active">
                  <div class="card-image">
                      <figure class="image">
                          <i class="fa fa-solid fa-users fa-2x" style="float:left; padding-left:10px;"></i>
                          <br>
                          <p class="title is-5" style="padding:10px;">Users</p>
                      </figure>
                  </div>
                  <div class="card-content">
                      <div class="content">
                          <p class="title" style="font-size:3em; color:white;">{{SystemAdminOverview.UserCount}}</p>
                      </div>
                  </div>
              </div>
              <div class="card card-secondary" routerLink="/organizations" routerLinkActive="active">
                  <div class="card-image">
                      <figure class="image">
                          <i class="fa fa-solid fa-cog fa-2x" style="float:left; padding-left:10px;"></i>
                          <br>
                          <p class="title is-5" style="padding:10px;">Organizations</p>
                      </figure>
                  </div>
                  <div class="card-content">
                      <div class="content">
                          <p class="title" style="font-size:3em; color:#2E8637;">{{SystemAdminOverview.OrganizationCount}}</p>
                      </div>
                  </div>
              </div>
              <div class="card card-secondary" routerLink="/roles" routerLinkActive="active">
                  <div class="card-image">
                      <figure class="image">
                          <i class="fa fa-solid fa-address-book fa-2x" style="float:left; padding-left:10px;"></i>
                          <br>
                          <p class="title is-5" style="padding:10px;">User Roles</p>
                      </figure>
                  </div>
                  <div class="card-content">
                      <div class="content">
                          <p class="title" style="font-size:3em; color:#2E8637;">{{SystemAdminOverview.RoleCount}}</p>
                      </div>
                  </div>
              </div>
              <div class="card card-secondary" routerLink="/emailtemplates" routerLinkActive="active">
                  <div class="card-image">
                      <figure class="image">
                          <i class="fa fa-solid fa-envelope fa-2x" style="float:left; padding-left:10px;"></i>
                          <br>
                          <p class="title is-5" style="padding:10px;">Email Templates</p>
                      </figure>
                  </div>
                  <div class="card-content">
                      <div class="content">
                          <p class="title" style="font-size:3em; color:#2E8637;">{{SystemAdminOverview.EmailTemplateCount}}</p>
                      </div>
                  </div>
              </div>
            </div>
      </div>
  </section>