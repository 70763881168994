import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { LoginServiceBase } from './login.service.base';

@Injectable({
  providedIn: 'root'
})
export class Mockloginservice implements LoginServiceBase {

  @Output()
  public LoginStatusChanged = new EventEmitter<any>();
  @Output()
  public OrganizationChanged = new EventEmitter<any>();
  public RequiresPasswordReset: boolean = false;

  constructor(private router: Router, private modalService: SimpleModalService) {
    this.LoginStatusChanged.emit(true);
    this.OrganizationChanged.emit(this.UserData.OrganizationId);

  }

  private UserData = {
    UserId: "efc97f03780c4cd591bd544758ed0272",
    FirstName: "Michael on AC1",
    SID: "iubCtHAt15u3zcMo+Zw/dciMGVBciQyLuWirn6AwC4nMBZjPn8aF41lYyknBHjGqRWcgVGwqZ5M/z3snrTB7Y/uC8vu/0tw/Ybs6dnPzKs9RSsXqjcPX1W3JUoDeW5UR",
    OrganizationId: "40975a05-07a9-45d3-81c9-7920c3c102e0",
    Permissions: [
      {
        DisplayName: "ControlledAreasFeature",
        IsCustom: "False",
        Key: "ControlledAreasFeature"
      },
      {
        Key: "LocalControllersFeature",
        DisplayName: "LocalControllersFeature",
        IsCustom: false
      },
      {
        Key: "CarCountersFeature",
        DisplayName: "CarCountersFeature",
        IsCustom: false
      },
      {
        Key: "LegacySignsFeature",
        DisplayName: "LegacySignsFeature",
        IsCustom: false
      },
      {
        Key: "ParkingSessionsFeature",
        DisplayName: "ParkingSessionsFeature",
        IsCustom: false
      },
      {
        Key: "PriceRulesFeature",
        DisplayName: "PriceRulesFeature",
        IsCustom: false
      },
      {
        Key: "ReportsFeature",
        DisplayName: "ReportsFeature",
        IsCustom: false
      },
      {
        Key: "WhitelistPlatesFeature",
        DisplayName: "WhitelistPlatesFeature",
        IsCustom: false
      },
      {
        Key: "StandardPermitsFeature",
        DisplayName: "StandardPermitsFeature",
        IsCustom: false
      },
      {
        Key: "LPRFeature",
        DisplayName: "LPRFeature",
        IsCustom: false
      },
      {
        Key: "OrganizationUsersFeature",
        DisplayName: "OrganizationUsersFeature",
        IsCustom: false
      },
      {
        Key: "ReportsFeature",
        DisplayName: "ReportsFeature",
        IsCustom: false
      },
      {
        Key: "CarpoolPermitsFeature",
        DisplayName: "CarpoolPermitsFeature",
        IsCustom: false
      },
      {
        Key: "ParkingLotsFeature",
        DisplayName: "ParkingLotsFeature",
        IsCustom: false
      },
      {
        Key: "AlertTriggersFeature",
        DisplayName: "AlertTriggersFeature",
        IsCustom: false
      },
      {
        Key: "EmailTemplatesFeature",
        DisplayName: "EmailTemplatesFeature",
        IsCustom: false
      },
      {
        Key: "AS10",
        DisplayName: "AS10",
        IsCustom: false
      },
      {
        Key: "Parking.CanPermitPark",
        DisplayName: "Can Permit Park",
        IsCustom: false
      }
    ]
  };


  public IsLoggedIn(): boolean {
    return this.UserData.SID != "";
  }
  public LoggedInFirstname(): string {
    return this.UserData.FirstName;
  }
  public CurrentToken(): string {
    return this.UserData.SID;
  }
  public CurrentOrganizationId(): string {
    return this.UserData.OrganizationId;
  }
  public UserId(): string {
    return this.UserData.UserId;
  }
  public async DoLogin(username: string, password: string): Promise<any> {
    this.LoginStatusChanged.emit(true);
    return { Success: true };

  }
  public DoLogout(): void {
    throw new Error('Method not implemented.');
  }
  public DoOrganizationSwitch(id: string): void {
    throw new Error('Method not implemented.');
  }
  public NotifyAuthExpired(): void {
    throw new Error('Method not implemented.');
  }
  public DebugBreakAuthToken(): void {
    this.UserData.SID = "----";
    localStorage.setItem("UserData", JSON.stringify(this.UserData));
  }
}
