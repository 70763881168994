import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceBase } from 'src/app/Services/api.service.base';
import { LocalStorageService } from 'src/app/Services/local-storage.service';
import { IFilter, IFilterFilters } from '../ifilter';

@Component({
  selector: '[app-rates-filter]',
  templateUrl: './rates-filter.component.html',
  styleUrls: ['./rates-filter.component.scss'],
  host: {'class':'control select'}
})
export class RatesFilterComponent implements OnInit, IFilterFilters {

  private localStorageKey = "Data_View_Filter";

  constructor(private apiService: ApiServiceBase, private localStorageService: LocalStorageService, private router: Router) {
    this.localStorageKey = this.router.url + '-' + this.localStorageKey;



  }
  public Filters: any[] = [];
  public SelectedRateSet: any = "";
  public RateSets: any;

  ngOnInit(): void {

    let storedFilter = this.localStorageService.Get(this.localStorageKey);
    if (storedFilter != null) {


      this.applySavedRateSetSelection(storedFilter.Filters);
    }

    if (this.SelectedRateSet != "") {
      this.Filters.push({
        Property: "ParkingRateSetId",
        Comparator: "==",
        Value: this.SelectedRateSet
      })
    }

    this.apiService.Get<any>("/parking/ratesets").then(result => {
      this.RateSets = result;

    });
  }



  public addRateSetFilter(event: any) {
    var val = event.target.value;
    this.Filters = this.Filters.filter(x => x.Property != "ParkingRateSetId");

    if (val != "") {
      this.Filters.push({
        Property: "ParkingRateSetId",
        Comparator: "==",
        Value: val
      });

      this.SelectedRateSet = val;
    }
  }

  applySavedRateSetSelection(filters: any) {
    let rateFilters = filters.filter((x: any) => x.Property == "ParkingRateSetId");
    if (rateFilters != null && rateFilters.length == 1) {
      this.SelectedRateSet = rateFilters[0].Value;
    }

  }

  public RateSetSelected(rateSet: any) {

    if (rateSet.Id == this.SelectedRateSet) {
      return true;
    }

    return false;
  }

}
