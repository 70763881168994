import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Busyable } from 'src/app/components/busyable';
import { BooleanViewColumn, Datalistviewcolumn } from 'src/app/components/datalistviewcolumn';
import { ApiServiceBase } from 'src/app/Services/api.service.base';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends Busyable implements OnInit {
  public ModelId: any;
  public userInfo: any;
  public editPage: any;
  public ShowAlertsDropdown: boolean = false;
  public FormLoaded: boolean = false;
  constructor(private apiService: ApiServiceBase, private parentRoute: ActivatedRoute) { 
    super();
  }

  ngOnInit(): void {
    this.Loading();
    this.parentRoute.params.subscribe((params : any) => { this.ModelId = params['id']; });
    this.editPage = this.parentRoute + "/edit";

    this.apiService.Get<any>("/master/users/" + this.ModelId).then(result => {
      this.userInfo = result;
      this.FormLoaded = true;
      this.StopLoading();
    });
  }

  public UserColumns : Array<Datalistviewcolumn> = [
    new Datalistviewcolumn("UserId", "Id"),
    new Datalistviewcolumn("Organization Name", "OrganizationName"),
    new Datalistviewcolumn("Organization Short Id", "ShortId"),
    new BooleanViewColumn("Admin Panel Access", "IsAdmin"),
    new BooleanViewColumn("IsActive", "IsActive", true),
    new Datalistviewcolumn("Last Sign In", "LastSignedInOnFriendlyString"),
    new Datalistviewcolumn("TimeZoneDisplayName", "TimeZoneDisplayName")
  ];
}
